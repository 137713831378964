import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles, styled } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Axios from "axios";
import { visuallyHidden } from "@mui/utils";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Checkbox
} from "@mui/material";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import {
  GET_CRONE_DETAILS,
  GET_EXPORTED_LIST,
  GET_INSTANT_EXPORT,
  GET_INVOICE_FIELDS,
  SAVE_CRON_DETAILS,
  UPDATE_CRON_DETAILS,
  ACCESS_S3_FILE,
  UPDATE_IS_ERP
} from "../comman/url";
import {
  deCryptFun,
  enCryptFun,
  getSenderEmail,
  getTeamID,
} from "../comman/function";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import API from "../constants/API";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import ExportInvoiceFilter from "../components/Modal/ExportInvoiceFilter";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PagenationPages from "../components/PagenationPages";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  notifydownloadbtn: {
    // margin: "12px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
  tabledownloadbtn: {
    margin: "12px 0px 0px 8px !important",
    fontSize: "14px !important",
    color: "#EC7C37 !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    border: "1px solid #EC7C37 !important",
    "&:hover": {
      // backgroundColor: "#D0580F !important",
      // color: "white !important",
      border: "1px solid #EC7C37",
      backgroundColor: "white !important",
    },
  },
  exportgrid: {
    top: "111px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  let aData = a[orderBy];
  let bData = b[orderBy];
  if (orderBy == "exportedTime") {
    aData = new Date(a.exportedDate).getTime();
    bData = new Date(b.exportedDate).getTime();
  }
  if (bData < aData) {
    return -1;
  }
  if (bData > aData) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "index",
    numeric: true,
    disablePadding: false,
    label: "S.No",
  },
  {
    id: "exportedTime",
    numeric: true,
    disablePadding: false,
    label: "Time stamp",
  },
  {
    id: "exportType",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },

  {
    id: "Action",
    numeric: true,
    disablePadding: false,

    label: "Invoice Received",
  },
];
let cronScheduleType = [
  { label: "Monthly", value: "Monthly" },
  { label: "Weekly", value: "Weekly" },
  { label: "Everyday", value: "Daily" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className="custom-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

export default function ExportInvoiceData(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { setActiveTab, invoiceFieldCount } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const styleclasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingExport,setloadingExport]=useState(false);
  const [exportData, setExportData] = useState([]);
  const [totalRecorts, settotalRecorts] = useState(0);
  const [cronJobChoise, setCronJobChoise] = useState("");
  const [everyDayTime, setEveryDayTime] = useState("00:00");
  const [weekDay, setWeekDay] = useState(1);
  const [monthDay, setMonthDay] = useState(1);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [cronId, setCronId] = useState(0);
  const [exportCount, setExportCount] = useState(0);

  const [exportCheckList, setExportCheckList] = useState([]);

  const [editFlag, setEditFlag] = useState(false);
  const [timeChanged, setTimeChanged] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [dataparams, setDataParams] = useState({
    offset: 0,
    count: 10,
  });
  // console.log(weekDay);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const nextPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previorsPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const rowHandle = (ve) => {
    setDataParams((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const getExportedList = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_EXPORTED_LIST,
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);

      if (status === "Success") {
        // setExportData(data.map((v, index) => ({ ...v, index: index + 1 })));

        setExportData(data)
        settotalRecorts(data.length);

        console.log(data.length,"eport")
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        console.log("Error:", v.message);
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getExportedList();
  }, []);
  var fileDownload = require("js-file-download");
  const downloadcsv = async (file) => {
 
    if (file) {
      const config = {
        method: "POST",
        url: ACCESS_S3_FILE,
        data: { filePath: file },
        headers: authHeader(),
      };
      try {
        const response = await API(config);
        const { status,url} = response.data;

        if (status === "Success") {
          // setDownloadURL(url);
          const segments = new URL(url).pathname.split('/');
          const last = segments.pop() || segments.pop(); // Handle potential trailing slash
        
          Axios.get(url, {
            responseType: "blob",
          }).then((res) => {
            fileDownload(
              res.data,
              last
              
            );
          });
        }
      } catch (error) {
        let errorObj = Object.assign({}, error);
        let { data } = errorObj.response;
        let { message } = data;
        Swal.fire(getAlertToast("Error", message));
      }
    } else {
      Swal.fire(getAlertToast("error", "No file is found!"));
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    dataparams.offset > 0
      ? Math.max(0, (1 + dataparams.offset) * dataparams.count - rows.length)
      : 0;
  // console.log(
  //   dataparams.count * (dataparams.offset + 1),
  //   dataparams.offset,
  //   totalRecorts
  // );
  const handleCronJobChange = (e) => {
    const { value } = e.target;
    console.log("cron", value);
    setCronJobChoise(value);
  };

  const timeHandle = (value) => {
    setTimeChanged(true);
    setEveryDayTime(value);
  };
  // console.log(monthDay, "out");

  const MonthlyCron = () => {
    const [value, setValue] = React.useState(null);
    const monthHandle = (e) => {
      const { value } = e.target;
      setMonthDay(value);
    };

    // console.log(monthDay, "in");

    return (
      <>
        {" "}
        <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
          <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
            Day
          </Typography>
          <Select
            // sx={{ m: 1, minWidth: 180 }}
            fullWidth
            displayEmpty
            value={monthDay}
            onChange={monthHandle}
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* <MenuItem value="">Select</MenuItem> */}
            {Array.from(Array(32).keys()).map((el, i) => {
              if (el != 0)
                return (
                  <MenuItem key={i} value={el}>
                    {el}
                  </MenuItem>
                );
            })}
          </Select>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
          <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
            Time
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            sx={{ width: "100%" }}
          >
            <TimePicker
              sx={{ width: "100%" }}
              // label="Time "
              value={everyDayTime}
              onChange={timeHandle}
             // readOnly={true}
              closeOnSelect={false}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "100%" }} />
              )}
            />
            
          </LocalizationProvider>
        </Grid>
      </>
    );
  };

  const WeeklyCron = () => {
    let weekDayData = [
      { label: "Monday", value: 1 },
      { label: "Tuesday", value: 2 },
      { label: "Wednesday", value: 3 },
      { label: "Thursday", value: 4 },
      { label: "Friday", value: 5 },
      { label: "Saturday", value: 6 },
      { label: "Sunday", value: 7 },
    ];
    const handleWeekDayChange = (e) => {
      const { value } = e.target;

      setWeekDay(value);
    };
    console.log(weekDay);
    return (
      <>
        {" "}
        <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
          <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
            Day
          </Typography>
          <Select
            // sx={{ m: 1, minWidth: 180 }}
            fullWidth
            displayEmpty
            value={weekDay}
            onChange={handleWeekDayChange}
            inputProps={{ "aria-label": "Without label" }}
          >
            {weekDayData.map((el, i) => {
              return (
                <MenuItem key={i} value={el.value}>
                  {el.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
          <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
            Time
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={everyDayTime}
              onChange={timeHandle}
              //label="Time "
              //readOnly={true}
              disableCloseOnSelect={false}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </>
    );
  };
  const DailyCron = () => {
    return (
      <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
        <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
          Time
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            //label="Time "
           // readOnly={true}
            value={everyDayTime}
            onChange={timeHandle}
            disableCloseOnSelect={false}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    );
  };

  const CroneSchedulle = (props) => {
    const { cronJobChoise } = props;
    const CroneModal = {
      Monthly: MonthlyCron,
      Weekly: WeeklyCron,
      Daily: DailyCron,
    };

    let SelectedFiels = CroneModal[cronJobChoise];
    return cronJobChoise ? <SelectedFiels /> : <div></div>;
  };
  const getCroneScheduleDetails = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_CRONE_DETAILS,
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "success") {
        if (data) {
          setEditFlag(true);

          setEveryDayTime(
            new Date(moment(data.exportTime, ["HH.mm"]).format())
          );

          console.log(data);
          setMonthDay(data.exportedDay == 0 ? 1 : data.exportedDay);
          setWeekDay(
            data.exportedDay == 0 || data.exportedDay >= 7
              ? 1
              : data.exportedDay
          );
          setCronJobChoise(data.exportType == 0 ? 1 : data.exportType);
          setCronId(data.csvExportId);
        }
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // Swal.fire("Error", data.message);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        // Swal.fire("Error", v.message);
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  const saveCronDetails = (e) => {
    // const cronType = e.currentTarget.dataset.type;
    // console.log("save", cronType)
    let checkExport = exportCheckList.filter((el) => {
      return el.isExport === 1;
    });
    if (checkExport.length > 0) {
      saveCronDetailsApi();
    } else {
      Swal.fire({
        title: "Please select fields to export !",
        icon: "Info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setActiveTab("1");
        }
      });
    }
    saveCronDetailsApi();
  };
  // const saveCronDetailsApi = async () => {
  //   setEditFlag(true);

  //   let convertedTime = moment(everyDayTime, "hh:mm A").format("HH:mm");
  //   console.log("convertedTime", convertedTime);
  //   const config = {
  //     method: "POST",
  //     url: SAVE_CRON_DETAILS,
  //     data: {
  //       webString: enCryptFun(
  //         JSON.stringify({
  //           teamId: getTeamID(),
  //           exportType: cronJobChoise,
  //           exportedDay:
  //             cronJobChoise === "Monthly"
  //               ? monthDay
  //               : cronJobChoise === "Weekly"
  //               ? weekDay
  //               : 0,
  //           exportTime: convertedTime,
  //         })
  //       ),
  //       flutterString: "",
  //     },
  //     headers: authHeader(),
  //   };
  //   console.log("data payload", {
  //     teamId: getTeamID(),
  //     exportType: cronJobChoise,
  //     exportedDay:
  //       cronJobChoise === "Monthly"
  //         ? monthDay
  //         : cronJobChoise === "Weekly"
  //         ? weekDay
  //         : 0,
  //     exportTime: convertedTime,
  //   });
  //   console.log("before try config", config);
  //   try {
  //     const response = await API(config);
  //     //const { status, data } = response.data;
  //     let l = deCryptFun(response.data);
  //     const { id, status, data } = JSON.parse(l);
  //     console.log("jsonParsed", JSON.parse(l));
  //     if (status === "Success") {
  //       // setEditFlag(true)
  //       setCronId(id);
  //       console.error(data);
  //       // Swal.fire(getAlertToast("success", "Saved Successfully"));
  //       CustomMessage("Saved Successfully", "success", enqueueSnackbar); //call toster
  //       getCroneScheduleDetails();
  //       getExportedList();
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       let { data } = error.response;

  //       let p = deCryptFun(data);
  //       let v = JSON.parse(p);
  //       // console.log("error data new",  v.message)
  //       Swal.fire("Error", v.message);
  //     }
  //   } finally {
  //     setSubmitLoading(false);
  //     setTimeChanged(false);
  //   }
  // };

    
  const saveCronDetailsApi = async () => {
    setEditFlag(true);

    let convertedTime = moment(everyDayTime, "hh:mm A").format("HH:mm");
    console.log(convertedTime);
    let convertedTimeHour = moment(everyDayTime).format("HH");
    let convertedTimeMinutes = moment(everyDayTime).format("mm");

    const config = {
      method: "POST",
      url: SAVE_CRON_DETAILS,
      data: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            exportType: cronJobChoise,       
            exportedDay:
            cronJobChoise === "Monthly"
            ? monthDay
            : 0,
              exportHour: convertedTimeHour,
              exportMinute: convertedTimeMinutes,
              exportDayOfWeek: cronJobChoise === "Weekly" ? weekDay : 0,
              exportMonth:0,
              senderEmail:getSenderEmail(), 
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    console.log("data payload", {
      teamId: getTeamID(),
      exportType: cronJobChoise,
      exportedDay:
        cronJobChoise === "Monthly"
          ? monthDay
          : cronJobChoise === "Weekly"
          ? weekDay
          : 0,
      exportTime: convertedTime,
    });
    console.log("before try config", config);
    try {
      const response = await API(config);
      //const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { id, status, data } = JSON.parse(l);
      console.log("jsonParsed", JSON.parse(l));
      if (status === "Success") {
        // setEditFlag(true)
        setCronId(id);
        console.error(data);
        // Swal.fire(getAlertToast("success", "Saved Successfully"));
        CustomMessage("Saved Successfully", "success", enqueueSnackbar); //call toster
        getCroneScheduleDetails();
        getExportedList();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire("Error", v.message);
      }
    } finally {
      setSubmitLoading(false);
      setTimeChanged(false);
    }
  };
  // const updateCronDetails = async () => {
  //   let convertedTime = moment(everyDayTime).format("HH:mm");
  //   console.log(convertedTime);
  //   if (convertedTime == "Invalid date") {
  //     CustomMessage("Invalid Time", "error", enqueueSnackbar);
  //     return null;
  //   }
  //   const config = {
  //     method: "PUT",
  //     url: UPDATE_CRON_DETAILS,
  //     data: {
  //       webString: enCryptFun(
  //         JSON.stringify({
  //           csvExportId: cronId,
  //           teamId: getTeamID(),
  //           exportType: cronJobChoise,
  //           exportedDay:
  //             cronJobChoise === "Monthly"
  //               ? monthDay
  //               : cronJobChoise === "Weekly"
  //               ? weekDay
  //               : 0,
  //           exportTime: convertedTime,
  //         })
  //       ),
  //       flutterString: "",
  //     },
  //     headers: authHeader(),
  //   };
  //   console.log("data payload on update", {
  //     teamId: getTeamID(),
  //     csvExportId: cronId,
  //     exportType: cronJobChoise,
  //     exportedDay:
  //       cronJobChoise === "Monthly"
  //         ? monthDay
  //         : cronJobChoise === "Weekly"
  //         ? weekDay
  //         : 0,
  //     exportTime: convertedTime,
  //   });
  //   console.log("before try config", config);
  //   try {
  //     const response = await API(config);
  //     //const { status, data } = response.data;
  //     let l = deCryptFun(response.data);
  //     const { status, data } = JSON.parse(l);
  //     console.log("jsonParsed", JSON.parse(l));
  //     if (status === "success") {
  //       console.error(data);
  //       // Swal.fire(getAlertToast("success", "Updated Successfully"));
  //       CustomMessage("Updated Successfully", "success", enqueueSnackbar); //call toster

  //       getCroneScheduleDetails();
  //       getExportedList();
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       let { data } = error.response;

  //       let p = deCryptFun(data);
  //       let v = JSON.parse(p);
  //       // console.log("error data new",  v.message)
  //       // Swal.fire("Error", v.message);
  //       CustomMessage(v.message, "error", enqueueSnackbar); //call toster
  //     }
  //   } finally {
  //     setSubmitLoading(false);
  //     setTimeChanged(false);
  //   }
  // };

  const updateCronDetails = async () => {
    let convertedTime = moment(everyDayTime).format("HH:mm");
    let convertedTimeHour = moment(everyDayTime).format("HH");
    let convertedTimeMinutes = moment(everyDayTime).format("mm");

    if (convertedTime == "Invalid date") {
      CustomMessage("Invalid Time", "error", enqueueSnackbar);
      return null;
    }
  
    const config = {
      method: "PUT",
      url: UPDATE_CRON_DETAILS,
      data: {
        webString: enCryptFun(
          JSON.stringify({
            csvExportId: cronId,
            teamId: getTeamID(),
            exportType: cronJobChoise,
            exportedDay:
              cronJobChoise === "Monthly"
                ? monthDay
                : 0,
                exportHour: convertedTimeHour,
                exportMinute: convertedTimeMinutes,
                exportDayOfWeek: cronJobChoise === "Weekly" ? weekDay : 0,
                exportMonth:0,
                senderEmail:getSenderEmail(),     
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    console.log("data payload on update", {
      teamId: getTeamID(),
      csvExportId: cronId,
      exportType: cronJobChoise,
      exportedDay:
        cronJobChoise === "Monthly"
          ? monthDay
          : cronJobChoise === "Weekly"
          ? weekDay
          : 0,
      exportTime: convertedTime,
    });
    console.log("before try config", config);
    try {
      const response = await API(config);
      //const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      console.log("jsonParsed", JSON.parse(l));
      if (status === "success") {
        console.error(data);
        // Swal.fire(getAlertToast("success", "Updated Successfully"));
        CustomMessage("Updated Successfully", "success", enqueueSnackbar); //call toster

        getCroneScheduleDetails();
        getExportedList();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setSubmitLoading(false);
      setTimeChanged(false);
    }
  };

  const updateCron = (e) => {
    let checkExport = exportCheckList.filter((el) => {
      return el.isExport === 1;
    });
    if (exportCount == 0) {
      Swal.fire({
        title: "Please select fields to export !",
        icon: "Info",
        // showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        fontSize: "1.5em",
        confirmButtonText: "ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setActiveTab("1");
        }
      });
    } else if (checkExport.length != exportCount) {
      Swal.fire({
        title: "Please update Seleted fields",
        icon: "Info",
        // showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setActiveTab("1");
        }
      });
    } else {
      updateCronDetails();
    }
  };
  const GetRequiredValues = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_INVOICE_FIELDS,
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, isExportCount } = JSON.parse(l);
      if (status === "Success") {
        setExportCheckList(data);
        setExportCount(isExportCount);
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // Swal.fire("Error", data.message);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCroneScheduleDetails();
    GetRequiredValues();
  }, []);

  const getInstantReport = async () => {
    if (invoiceFieldCount == 0) {
      CustomMessage(
        "Please select the fields to export",
        "error",
        enqueueSnackbar
      ); //call toster
    } else {
      const configset = {
        method: "GET",
        url: GET_INSTANT_EXPORT,
        params: {
          // teamId: getTeamID(),
          // dateFormat: type || "MM/dd/yyyy",
          webString: enCryptFun(
            JSON.stringify({
              teamId: getTeamID(),
              subscriberEmail: getSenderEmail(),
            })
          ),
          flutterString: "",
        },
        headers: authHeader(),
      };

      try {
        const response = await API(configset);
        //const { status } = response.data;
        let l = deCryptFun(response.data);
        const { status, message } = JSON.parse(l);
        console.log("JSON Parsed", JSON.parse(l));
        if (
          status === "Success" &&
          message == "Report generated successfully"
        ) {
          getExportedList();
          CustomMessage(message, "success", enqueueSnackbar); //call toster
        } else if (status === "Success" && message == "No data found") {
          // Swal.fire(message);

          CustomMessage(
            "No invoices have been approved since the last export",
            "error",
            enqueueSnackbar
          ); //call toster
        } else {
          CustomMessage(message, "error", enqueueSnackbar);
        }
      } catch (error) {
        if (error.response) {
          let { data } = error.response;

          let p = deCryptFun(data);
          let v = JSON.parse(p);
          // console.log("error data new",  v.message)
          // Swal.fire("Error", v.message);
          CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        }
      }
    }
  };

  const updateERPCall =async(exportId,checked)=>{
const config={
  method: "PUT",
  url: UPDATE_IS_ERP,
  headers: authHeader(),
  data:{
    webString: enCryptFun(
      JSON.stringify({
       isErp:checked,
       exportId:exportId
      })
    ),
    flutterString: "",
  },
}
try{
  const response = await API(config);
  let l = deCryptFun(response.data);
  const { status, message } = JSON.parse(l);
  if(status==="success"){
  CustomMessage(message, "success", enqueueSnackbar);
  getExportedList()
  }
}
catch(error){
  if (error.response) {
    let { data } = error.response;

    let p = deCryptFun(data);
    let v = JSON.parse(p);
     console.log("error data new",  v.message)
    // Swal.fire(getAlert("Error", v.message));
  }
}
  }
  const filterToggle = () => {
    setFilterOpen(!filterOpen);
  };

  // const filterClose = () => {
  //   setFilterOpen(false);
  // };
  const updateErp =(e)=>{
    const { checked } = e.target;
     const exportId=e.currentTarget.dataset.exportid
     console.log(exportId,"exportID")
     const newState = exportData.map(obj => {
      if (obj.exportId == exportId) {
        return {...obj, isERP: checked?1:0};
      }
      return obj;
    });
    setExportData(newState);
    updateERPCall(exportId,checked)
  }
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Typography>Export Data Schedule</Typography>
      <Grid item xs={12} md={12} sx={{ display: "flex" }}>
        <Select
          sx={{ m: 1, minWidth: 180 }}
          displayEmpty
          value={cronJobChoise}
          onChange={handleCronJobChange}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="">Select</MenuItem>
          {cronScheduleType.map((el, i) => {
            return (
              <MenuItem key={i} value={el.value}>
                {el.label}
              </MenuItem>
            );
          })}
        </Select>

        <CroneSchedulle cronJobChoise={cronJobChoise} />
        <Button
          disabled={timeChanged === false}
          onClick={(e) =>
            editFlag === true ? updateCron(e) : saveCronDetails(e)
          }
        >
          {editFlag ? "Update" : "Save"}
        </Button>
        <Button onClick={getInstantReport}>Generate Now</Button>
      </Grid> */}

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ textAlign: "right", alignSelf: "end", mb: 2 }}
        className={styleclasses.exportgrid}
      >
        <Button
          onClick={getInstantReport}
          className={styleclasses.notifydownloadbtn}
          variant="contained"
        >
          Generate now
        </Button>

        {/* <LightTooltip
          title="Schedule by"
          placement="top"
          className={styleclasses.filterby}
        > */}
        <Tooltip
          title="Schedule by"
          placement="top"
          arrow
          classes={{
            arrow: styleclasses.arrow,
            tooltip: styleclasses.tooltip,
          }}
        >
          <IconButton onClick={filterToggle}>
            <FilterListRoundedIcon className={styleclasses.filtercolor} />
          </IconButton>
        </Tooltip>
        {/* </LightTooltip> */}
        {filterOpen && (
          <ExportInvoiceFilter
            open={filterOpen}
            close={filterToggle}
            cronJobChoise={cronJobChoise}
            handleCronJobChange={handleCronJobChange}
            cronScheduleType={cronScheduleType}
            CroneSchedulle={CroneSchedulle}
            editFlag={editFlag}
            timeChanged={timeChanged}
            updateCron={updateCron}
            saveCronDetails={saveCronDetails}
          />
        )}
      </Grid>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
            {loading && (
                <TableRow align="center">
                  <TableCell colSpan={7}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {loading ? null : exportData.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {!loading && stableSort(exportData, getComparator(order, orderBy))
                    .slice(
                      dataparams.offset * dataparams.count,
                      dataparams.offset * dataparams.count + dataparams.count
                    )
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((datalist) => {
                      const {
                        exportId,
                        exportedTime,
                        exportedDate,
                        type,
                        teamId,
                        filePath,
                        isERP
                      } = datalist;

                      var gmtDateTime = moment.utc(exportedDate, "YYYY-MM-DD");
                      var convert12Date = gmtDateTime
                        .local()
                        .format("YYYY-MM-DD");

                      let convert12Time = moment(exportedTime, "HH:mm").format(
                        "hh:mm A"
                      );

                      // console.log("exportedDate", exportedDate);
                      // console.log("convert12Time", convert12Time);

                      const labelId = `enhanced-table-checkbox-${exportId}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, name)}
                          tabIndex={-1}
                          key={exportId}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {exportId}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {convert12Date} <br /> {convert12Time}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            {type}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            <Button
                            onClick={()=>downloadcsv(filePath)}
                              className={styleclasses.tabledownloadbtn}
                              variant="outlined"
                              style={{
                                borderRadius: 50,
                              }}
                            >
                              <a style={{ color: "#EC7C37" }} >
                                Download
                              </a>
                            </Button>
                          </TableCell>
                          <TableCell
                                  align="center"
                                  style={{ fontWeight: 450, fontSize: 14 }}
                                >
                                  {" "}
                                  <Checkbox
                                    // onClick={handleAssignSupplier}
                                    // data-userid={userId}
                                    // checked={isDeleted == 0}
                                    inputProps={{
                                      "aria-label": "Checkbox demo",
                                      // "data-userid": userId,
                                    }}
                                  />
                                </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              )}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <PagenationPages
        dataparams={dataparams}
        rowHandle={rowHandle}
        totalRecord={totalRecorts}
        previorsPage={previorsPage}
        nextPage={nextPage}
      />
    </Box>
  );
}
