import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  deCryptFun,
  enCryptFun,
  getApprovalAmount,
  getRole,
  getUserId,
} from "../comman/function";
import Paper from "@mui/material/Paper";
import InvoiceFormNewExtract from "./InvoiceFormNewExtract";
import { LOCK_INVOICE_URL } from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function PoNonpo(props) {
  const {
    status,
    onPoRadioChange,
    type,
    onNonpoRadioChange,
    orderNumber,
    invoiceAmount,
    teamMemberList,
    setTeamMemberId,
    handleTeamMemberChange,
    teamMemberId,
    formValues,
    newSupplierData,
    errors,
    dateShow,
    requiredFieldList,
    touched,
    handleChange,
    values,
    setValues,
    setFormValues,
    setErrors,
    invoiceID,
    keyChangeFlag,
    setKeyFlag,
    setKeyData,
    fieldArray,
    setFieldArray,
    keyData,
    detectedData,
    setDetectedData,
    dynamicVal,
    setDynamicVal,
    loadingdetect,
    handleBlur,
    dueAmount,
    setTouched,
    setInvoiceDateError,
    setDueDateError,
    dueDateError,
    invoiceDateError,
    unlockInvoice,
    isEdit,
  } = props;
  console.log("pononpo");
  const { enqueueSnackbar } = useSnackbar();

  // let invoiceAmountsymbol = values.invoiceAmount
  //   ?.toString()
  //   .split("$ ")
  //   .join("");
  // let invoiceAmountChanged = Math.floor(invoiceAmountsymbol);
  // let invoiceAmountChanged = parseFloat(
  //   values.invoiceAmount?.toString().replace(/\$|,/g, "")
  // );
  // let invoiceAmountChanged = values.invoiceAmount
  //   ?.toString()
  //   .replace(/\$|,/g, "");
  let invoiceAmountChanged = parseFloat(
    values.invoiceAmount?.replace(/\$|,/g, "").toString()
  );

  // let dueAmountsymbol = values.dueAmount?.toString().split("$ ").join("");
  // let dueAmountChanged = Math.floor(dueAmountsymbol);
  // let dueAmountChanged = parseFloat(
  //   values.dueAmount?.toString().replace(/\$|,/g, "")
  // );

  let dueAmountChanged = parseFloat(
    values.dueAmount?.toString().replace(/\$|,/g, "")
  );
  const handleLockInvoice = async (teamMemberId, e) => {
    const config = {
      method: "POST",
      url: LOCK_INVOICE_URL,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            lockedBy: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        if (
          parseInt(data.lockedUserId) > 0 &&
          parseInt(data.lockedUserId) !== parseInt(getUserId())
        ) {
          // Swal.fire(
          //   getAlertToast(
          //     "error",
          //     "This invoice is locked for editing as another user is currently editing invoice fields."
          //   )
          // );
          CustomMessage(
            "This invoice is locked for editing as another user is currently editing invoice fields.",
            "error",
            enqueueSnackbar
          ); //call toster
        } else {
          // dispatch({ type: TURN_FLAG, payload: !isEdit });

          // localStorage.setItem("EDIT_IN_PROCESS", true);
          handleTeamMemberChange(teamMemberId, e);
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
    // handleTeamMemberChange(teamMemberId, e);
  };

  return (
    <div className="invoiceDetails">
      <div className="mb-4">
        {/* <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography className="w-100 mt-4" check>
            {((status && status === "Approved") ||
              status === "Auto Approved") && (
              <Box
                component="h6"
                sx={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  margin: "0px",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                <Box
                  // className="mr-2"
                  component={"span"}
                  for="invoiceType"
                  sx={{
                    mr: 2,
                    fontWeight: "normal",
                  }}
                >
                  Invoice Type:{" "}
                </Box>
                {orderNumber ? "PO" : "NON PO"}
              </Box>
            )}

            {status && status !== "Approved" && status !== "Auto Approved" && (
              <Fragment>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="PO"
                    name="type"
                    control={
                      <Radio
                        checked={type === "po" ? true : false}
                        onChange={onPoRadioChange}
                      />
                    }
                    label="PO"
                  />
                  <FormControlLabel
                    value="nonpo"
                    name="type"
                    control={
                      <Radio
                        checked={type === "nonpo" ? true : false}
                        onChange={onNonpoRadioChange}
                      />
                    }
                    label="Non-PO"
                  />
                </RadioGroup>
              </Fragment>
            )}
          </Typography>
        </Box> */}
        {/* <h1>select supplier</h1> */}

        {/* {!isEdit && (
            <Grid
              container
              sx={{ mt: 2, mb: 2, alignItems: "center" }}
              spacing={2}
            >
              <Grid xs={4}>
                <Typography sx={{ color: "rgb(236, 124, 55)" }}>
                  Assign to
                </Typography>
              </Grid>
              <Grid xs={4}>
                {" "}
                <Select
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setTeamMemberId(e.target.value);
                  }}
                  name="newTM"
                  id="newTM"
                  // value={teamMemberId}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {teamMemberList.map((el, i) => {
                    const { userId, userName } = el;
                    return (
                      <MenuItem key={i} value={userId}>
                        {userName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid xs={4}>
                {" "}
                <Button
                  sx={{ color: "rgb(236, 124, 55)" }}
                  color="primary"
                  // onClick={(e) => handleLockInvoice(teamMemberId, e)}
                  onClick={(e) => handleTeamMemberChange(teamMemberId, e)}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          )} */}
      </div>
      {/* {console.log("Before:", values.invoiceAmount, values.dueAmount)}
      {console.log("After:", invoiceAmountChanged, dueAmountChanged)} */}
      <InvoiceFormNewExtract
        // isEdit={isEdit}
        errors={errors}
        dateShow={dateShow}
        requiredFieldList={requiredFieldList}
        touched={touched}
        handleChange={handleChange}
        unlockInvoice={unlockInvoice}
        // values={{
        //   ...values,
        //   invoiceAmount: Math.floor(invoiceAmountChanged),
        //   dueAmount: Math.floor(dueAmountChanged),
        // }}
        values={values}
        defaultInvoiceType={type}
        setFormValues={setFormValues}
        formValues={formValues}
        setValues={setValues}
        setErrors={setErrors}
        handleBlur={handleBlur}
        invoiceID={invoiceID}
        keyChangeFlag={keyChangeFlag}
        setKeyFlag={setKeyFlag}
        keyData={keyData}
        setKeyData={setKeyData}
        fieldArray={fieldArray}
        setFieldArray={setFieldArray}
        detectedData={detectedData}
        setDetectedData={setDetectedData}
        dynamicVal={dynamicVal}
        setDynamicVal={setDynamicVal}
        loadingdetect={loadingdetect}
        newSupplierData={newSupplierData}
        setTouched={setTouched}
        setInvoiceDateError={setInvoiceDateError}
        setDueDateError={setDueDateError}
        dueDateError={dueDateError}
        invoiceDateError={invoiceDateError}
      />
    </div>
  );
}

export default React.memo(PoNonpo);
