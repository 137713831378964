import React, { useEffect, useState } from "react";
import { Box, Paper, Grid, Button, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
function DashboardDailyReport(props) {
  const {
    invoicestatistics,
    weeklyReport,
    statisticsOfInvoice,
    invDistribution,
  } = props;

  const {
    supplierByPending,
    approvedVsPending,
    receivedInvoices,
    numberOfException,
    approvedCount,
  } = invoicestatistics;
  const { receivedVsPending, receivedVsException, pendingCount } = weeklyReport;
  const {
    // totalInvoice,
    // currentDate,
    approvedOnTime,
    unApprovedInvoices,
    nonPo,
    invoiceSubmission,
  } = statisticsOfInvoice;
  const [g7invcount, setg7invcount] = useState([]);
  const [g7suppliername, setg7suppliername] = useState([]);
  const [g6approveper, setg6approveper] = useState();
  const [weeklyreceived, setWeeklyreceived] = useState([]);
  const [weeklyPending, setWeeklyPending] = useState([]);
  const [weeklyApproved, setWeeklyApproved] = useState([]);
  const [weeklyException, setWeeklyException] = useState([]);
  const [approvedOntimeCount, setapprovedOntime] = useState("");
  const [isOverDue, setIsOverDue] = useState(0);
  const [approvedOvertimeCount, setapprovedOvertime] = useState("");
  const [nonpoCount, setnonpoCount] = useState("");
  const [poCount, setpoCount] = useState("");
  const [InvSubmissionCount, setInvSubmissionCount] = useState();
  const [SubmissionMethod, setSubmissionMethod] = useState();
  const [unApprovedInvoicesCount, setunApprovedInvoices] = useState();
  const [distributionAmt, setDistributionAmt] = useState();
  const [distributioncount, setDistributionCount] = useState();

  const [weeklyDay, setWeeklyDay] = useState([]);
  let datesReorder = [];
  for (let i = 0; i <= 6; i++) {
    datesReorder.push(
      new Date(Date.now() - 1000 * 3600 * (i * 24))
        .toLocaleDateString("en-GB")
        .replace("/", "-")
        .replace("/", "-")
    );
  }

  const invoiceStatisticsCall = () => {
    console.log("sup pending", supplierByPending);
    if (supplierByPending) {
      console.log(
        "sup pending 1",
        supplierByPending,
        supplierByPending
          .filter((x) => x.name !== "N/A" && x.name !== "null")
          .map((es) => es.name && es.name.split(/[ ,]+/))
      );

      setg7invcount(
        supplierByPending
          .filter((x) => x.name !== "null")
          .map((es) => es.totalCount)
      );
      setg7suppliername(
        supplierByPending
          .filter((x) => x.name !== "null")
          .map((es) => es.name && es.name.split(/[ ,]+/))
      );
    }

    setg6approveper((approvedCount / receivedInvoices) * 100);
    if (receivedVsPending) {
      setWeeklyreceived(
        receivedVsPending.map(function (wr) {
          return wr.Received === null ? 0 : wr.Received;
        })
      );

      setWeeklyPending(
        receivedVsPending.map(function (wr) {
          return wr.Pending === null ? 0 : wr.Pending;
        })
      );
      setWeeklyApproved(
        receivedVsPending.map(function (wr) {
          return wr.Approved === null ? 0 : wr.Approved;
        })
      );

      setWeeklyDay(receivedVsPending.map((wd) => wd.Day));
    }

    if (receivedVsException) {
      setWeeklyException(
        receivedVsException.map(function (wr) {
          return wr.Exception === null ? 0 : wr.Exception;
        })
      );
    }
    setapprovedOntime(
      approvedOnTime.filter((d) => d.isOverDue == 1).map((e) => e.totalCount)
    );
    setIsOverDue(
      approvedOnTime.filter((d) => d.isOverDue == 1).map((e) => e.isOverDue)
    );
    setapprovedOvertime(
      approvedOnTime.filter((d) => d.isOverDue == 0).map((e) => e.totalCount)
    );
    setnonpoCount(
      nonPo.filter((np) => np.isPO === 0).map((e) => e.invoiceCount)
    );
    setpoCount(nonPo.filter((np) => np.isPO === 1).map((e) => e.invoiceCount));
    setInvSubmissionCount(invoiceSubmission.map((sic) => sic.invoiceCount));
    setSubmissionMethod(
      invoiceSubmission.map((sic) => (sic.source === null ? "" : sic.source))
    );
    setDistributionCount(
      invDistribution.filter((x) => x.invoice !== 0).map((iv) => iv.invoice)
    );
    setDistributionAmt(
      invDistribution
        .filter((x) => x.invoice !== 0)
        .map((iv) =>
          iv.toAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
    );

    if (datesReorder) {
      let daterestructed = datesReorder.map((d) => {
        let o = unApprovedInvoices.find(
          (x) => dateFormate(x.CreatedDate) === d
        );
        return o ?? { totalCount: 0, CreatedDate: d };
      });

      let count = daterestructed.map((dr) => dr.totalCount);

      setunApprovedInvoices(count);
      // console.log(count.reverse(),weeklyreceived)
    }
  };

  const dateFormate = (date) => {
    return moment(date).utc().format("DD-MM-YYYY");
  };

  useEffect(() => {
    if (invoicestatistics && weeklyReport) {
      invoiceStatisticsCall();
    }
  }, [invoicestatistics, weeklyReport]);

  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }
  let randomFillColor = [];
  if (distributioncount) {
    for (var i = 0; i < distributioncount.length; i += 1) {
      randomFillColor.push(getRandomColor());
    }
  }

  //Percentage of invoice approved ontime
  console.log("approvedOntimeCount", approvedOntimeCount);
  let C8series = [
    approvedOntimeCount.length !== 0 ? approvedOntimeCount[0] : 1,
    approvedOvertimeCount.length !== 0 ? approvedOvertimeCount[0] : 0,
  ];

  // let C8series=[5,10]
  let C8options = {
    chart: {
      width: 380,
      type: "pie",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },

    labels: ["OverDue", "On-Time"],
    noData: {
      text: "There's no data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
  };
  //povsnonpo

  let C11series = [
    nonpoCount.length !== 0 && nonpoCount[0],
    poCount.length !== 0 && poCount[0],
  ];
  let C11options = {
    chart: {
      width: 380,
      type: "pie",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    labels: ["Non-PO", "PO"],
  };

  //invoice Submission
  let C12series = InvSubmissionCount;
  let C12options = {
    chart: {
      width: 380,
      type: "pie",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    labels: SubmissionMethod,
  };

  //chart 4 received vs pending
  const C4options = {
    tooltip: {
      enabled: false,
      x: {
        show: false,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },

    chart: {
      height: 350,
      type: "line",

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#77B6EA", "#f4b940"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      // text: "Average High & Low Temperature",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: weeklyDay,
    },
    yaxis: {
      forceNiceScale: true,

      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
      max: (max) => {
        max = Math.max(...C4series[0]["data"]);
        if (max < 4) {
          return "4";
        } else {
          return max;
        }
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      // floating: true,
      // offsetY: -25,
      // offsetX: -5
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    responsive: [
      {
        breakpoint: 700,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  };

  var C4series = [
    {
      name: "Received",
      data: weeklyreceived,
    },
    {
      name: "Pending",
      data: weeklyPending,
    },
  ];

  const C10options = {
    tooltip: {
      enabled: false,
      x: {
        show: false,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },

    chart: {
      height: 350,
      type: "line",

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#77B6EA"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      // text: "Average High & Low Temperature",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5, 6, 7],
      title: {
        text: "Number of Days",
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      // floating: true,
      // offsetY: -25,
      // offsetX: -5
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 700,
    //     options: {
    //       chart: {
    //         toolbar: {
    //           show: false,
    //         },
    //       },
    //       legend: {
    //         show: true,
    //       },
    //     },
    //   },
    // ],
  };

  var C10series = [
    {
      name: "Received",
      data: unApprovedInvoicesCount,
    },
  ];

  //chart5 received vs approved

  const C5options = {
    tooltip: {
      enabled: false,
      x: {
        show: false,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },

    chart: {
      height: 350,
      type: "line",

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#77B6EA", "#793ed6"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      // text: "Average High & Low Temperature",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: weeklyDay,
    },
    yaxis: {
      forceNiceScale: true,

      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
      max: (max) => {
        max = Math.max(...C5series[0]["data"]);
        if (max < 4) {
          return "4";
        } else {
          return max;
        }
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      // floating: true,
      // offsetY: -25,
      // offsetX: -5
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    responsive: [
      {
        breakpoint: 700,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  };

  var C5series = [
    {
      name: "Received",
      data: weeklyreceived,
    },
    {
      name: "Approved",
      data: weeklyApproved,
    },
  ];

  //c6 percentage of approved vs received
  let C6series = [g6approveper];
  let C6options = {
    chart: {
      sparkline: {
        enabled: true,
      },
      height: 350,
      type: "radialBar",
      // toolbar: {
      //   show: true
      // }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,

        track: {
          background: "#fff",
          strokeWidth: "87%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: 120,
            show: true,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "56px",
            offsetY: 76,
            show: true,
            textAnchor: "start",
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.1,

        colorStops: [
          {
            offset: 0,
            color: "#b48bf4",
            opacity: 1,
          },
          {
            offset: 20,
            color: "#8a58d9",
            opacity: 1,
          },
        ],
        inverseColors: true,

        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Percent"],
    noData: {
      text: "There's no data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
  };

  //C7 Supplier pending 24 hrs
  var C7options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,

      toolbar: {
        show: false,
      },
    },

    tooltip: {
      y: { title: { show: false, formatter: () => "" } },
    },

    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return "";
      },
      offset: 0,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
      },
    },
    xaxis: {
      tickAmount: 1,
      tickPlacement: "on",
      labels: {
        rotate: 0,
        formatter: function (val) {
            return parseInt(val);
           },
      },
      title: {
        text: "Number of Invoices",
      },

      type: "category",
      categories: g7suppliername,
    },
    yaxis: {
      // title: {
      //   text: "Supplier Name",
      // },
      formatter: function (val) {
        return parseInt(val);
      },
      // max: (max) => {
      //   max = Math.max(...C7series[0]["data"]);
      // },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    fill: {
      opacity: 1,
    },
    // noData: {
    //   text: "There's no data",
    //   align: 'center',
    //   verticalAlign: 'middle',
    //   offsetX: 0,
    //   offsetY: 0
    //   }
  };
  var C7series = [
    {
      name: "count",
      data: g7invcount,
    },
  ];
  var C9options = {
    tooltip: {
      enabled: false,
      x: {
        show: false,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },

    chart: {
      height: 350,
      type: "line",

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#77B6EA", "#EC7C37"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      // text: "Average High & Low Temperature",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: weeklyDay,
    },
    yaxis: {
      forceNiceScale: true,

      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
      max: (max) => {
        max = Math.max(...C9series[0]["data"]);
        if (max < 4) {
          return "4";
        } else {
          return max;
        }
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      // floating: true,
      // offsetY: -25,
      // offsetX: -5
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    responsive: [
      {
        breakpoint: 700,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  };

  var C9series = [
    {
      name: "Received",
      data: weeklyreceived,
    },
    {
      name: "Exception",
      data: weeklyException,
    },
  ];
  let C13series = distributioncount;
  let C13options = {
    chart: {
      width: 380,
      type: "pie",
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    fill: {
      colors: randomFillColor,
    },
    colors: randomFillColor,

    labels: distributionAmt,
  };

  return (
    <Grid id="main_editor_preview" container sx={{ mb: 2 }}>
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={6} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Received vs. Pending, Last 7 Days
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
              textAlign: "end",
            }}
            spacing={2}
          >
            <Box
              sx={{
                height: "350px",
              }}
            >
              <Tooltip
                sx={{ mt: "0px !important" }}
                title=" Provides a graphical analysis of your invoice turnover rate within the last 7 days. For example, if you received 5 invoices on Monday, and have approved 4 of them, the “Received” invoices would be 5, and the “Pending” invoices would be 1."
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              {(weeklyreceived && weeklyreceived.length > 0) ||
              (weeklyPending && weeklyPending.length > 0) ? (
                <ReactApexChart
                  options={C4options}
                  series={C4series}
                  type="line"
                  height="300"
                />
              ) : (
                "No Data"
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Received vs. Approved, Last 7 Days
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
              textAlign: "end",
            }}
            spacing={2}
          >
            <Box
              sx={{
                height: "350px",
              }}
            >
              <Tooltip
                sx={{ mt: "0px !important" }}
                title="Provides a graphical analysis of your invoice turnover rate within the last 7 days. For example, if you received 5 invoices on Monday, and have approved 4 of them, the “Received” invoices would be 5, and the “Approved” invoices would be 4."
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              {weeklyApproved || weeklyreceived ? (
                <ReactApexChart
                  options={C5options}
                  series={C5series}
                  type="line"
                  height="300"
                />
              ) : (
                "No Data"
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={6} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7, minHeight: "64px" }}
          >
            Percentage of Invoices Approved, Received in Last 7 Days
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
            }}
            spacing={2}
          >
            <Tooltip
              sx={{ mt: "0px !important", float: "right" }}
              title="Shows the percentage of invoices approved vs. number received in the last 7 days."
            >
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
            {g6approveper ? (
              <Box
                sx={{
                  height: "350px",
                }}
              >
                <ReactApexChart
                  options={C6options}
                  series={C6series}
                  type="radialBar"
                  height="300"
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: 350,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>No Approved Invoices yet</div>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7, minHeight: "64px" }}
          >
            Percentage of Invoices Approved on Time
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
            }}
            spacing={2}
          >
            <Box
              sx={{
                height: "350px",
              }}
            >
              <Box sx={{textAlign:"right"}}> <Tooltip
                      sx={{ mt: "0px !important" }}
                      title="Shows the percentage of invoices approved before/after the due date."
                    >
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip></Box>

              {approvedOntimeCount.length > 0 ? (
                approvedOntimeCount && approvedOvertimeCount ? (
                  <>
                   
                    <ReactApexChart
                      options={C8options}
                      series={C8series}
                      type="pie"
                      height="300"
                    />
                  </>
                ) : (
                  "No Approved Invoices"
                )
              ) : (
                <Box
                  sx={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                 

                  <div>No Approved Invoices yet</div>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5 }} container>
        <Grid item xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Invoices Pending, By Supplier
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
              minHeight: "350px",
              maxHeight: "350px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            spacing={2}
          >
            <Box
              sx={{
                height: "350px",
              }}
            >
              <Box  sx={{
                textAlign: "right",
              }}>
              <Tooltip
                sx={{ mt: "0px !important"}}
                title="Gives a breakdown of how many invoices are pending for each vendor."
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              </Box>
              {g7suppliername.length >= 1 ? (
                <ReactApexChart
                  options={C7options}
                  series={C7series}
                  type="bar"
                  height={
                    g7suppliername.length <= 4
                      ? "300"
                      : g7suppliername.length * 60
                  }
                />
              ) : (
                <Box
                  sx={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  No Pending invoices
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={6} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Received vs. Exception, Last 7 Days
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
              textAlign: "end",
            }}
            spacing={2}
          >
            <Box
              sx={{
                height: "350px",
              }}
            >
              <Tooltip
                sx={{ mt: "0px !important" }}
                title="Provides a graphical analysis of invoices received vs. how many invoices have had an exception over the last 7 days."
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              <ReactApexChart
                options={C9options}
                series={C9series}
                type="line"
                height="300"
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Non-PO vs. PO
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
             
            }}
            spacing={2}
          >
             <Box  sx={{
                textAlign: "right",
              }}>
                <Tooltip
                  sx={{ mt: "0px !important" }}
                  title=" Provides a percentage for the number of PO/Non-PO invoices received."
                >
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            {nonPo.length !== 0 && nonpoCount && poCount ? (
              <Box
                sx={{
                  height: "350px",
                }}
              >
                
                <ReactApexChart
                  options={C11options}
                  series={C11series}
                  type="pie"
                  height="300"
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: 300,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                No Invoices Submitted yet
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid sx={{ mt: 5 }} container>
          <Grid item sx={{ pr: 3 }} xs={12} md={6} justifyContent="left">
            <Typography
              align="left"
              variant="h5"
              sx={{ fontWeight: "600", mt: 3.7 }}
            >
              Invoice Capture Methods
            </Typography>

            <Grid
              xs={12}
              md={12}
              sx={{
                p: 3,
                mt: 2,
                backgroundColor: "#fff",
                borderRadius: "16px",
                boxShadow: "2px 2px 9px #0000001c",
                zIndex: "0 !important",
                width: "100%",
                
              }}
              spacing={2}
            >
              
            
                <Box
                  sx={{
                    height: "350px",
                  }}
                >
                  <Box sx={{textAlign:"right"}}>
                  <Tooltip
                    sx={{ mt: "0px !important" }}
                    title="Provides a breakdown of each method that can be used to capture an invoice."
                  >
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  </Box>
                  
                  {InvSubmissionCount && InvSubmissionCount.length !== 0 ? (  
                  <ReactApexChart
                    options={C12options}
                    series={C12series}
                    type="pie"
                    height="300"
                  /> ):
                  (<Box
                  sx={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div> No Invoices Submitted yet</div>
                </Box> )}
                </Box>
              
                
             
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} justifyContent="left">
            <Typography
              align="left"
              variant="h5"
              sx={{ fontWeight: "600", mt: 3.7 }}
            >
              Current Unapproved Invoices, Time in Queue
            </Typography>

            <Grid
              xs={12}
              md={12}
              sx={{
                p: 3,
                mt: 2,
                backgroundColor: "#fff",
                borderRadius: "16px",
                boxShadow: "2px 2px 9px #0000001c",
                zIndex: "0 !important",
                width: "100%",
                textAlign: "end",
              }}
              spacing={2}
            >
              <Box
                sx={{
                  height: "350px",
                }}
              >
                <Tooltip
                  sx={{ mt: "0px !important" }}
                  title="Shows how many invoices are still outstanding received in the last week. Utilize this graph to understand how long your organization is taking to turn over invoices."
                >
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <ReactApexChart
                  options={C10options}
                  series={C10series}
                  type="line"
                  height="300"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 5 }} container>
          <Grid item sx={{ pr: 3 }} xs={12} md={12} justifyContent="left">
            <Typography
              align="left"
              variant="h5"
              sx={{ fontWeight: "600", mt: 3.7 }}
            >
              Distribution of Invoice Totals
            </Typography>

            <Grid
              xs={12}
              md={12}
              sx={{
                p: 3,
                mt: 2,
                backgroundColor: "#fff",
                borderRadius: "16px",
                boxShadow: "2px 2px 9px #0000001c",
                zIndex: "0 !important",
                width: "100%",
                
              }}
              spacing={2}
            >
              <Box
                sx={{
                  height: "350px",
                }}
              >
                <Box sx={{textAlign:"right"}}>
                <Tooltip
                  sx={{ mt: "0px !important" }}
                  title="This graph utilizes the approval limits set for users under ‘My Team’, to define the value ranges found in the legend. Utilize this pie chart to understand how many invoices users can approve in your organization, and how many are being routed for approvals."
                >
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
                </Box>
                {distributionAmt && distributionAmt.length !== 0 ? (
                  randomFillColor &&
                  distributionAmt && (
                    <ReactApexChart
                      options={C13options}
                      series={C13series}
                      type="pie"
                      height="300"
                    />
                  )
                ) : (
                  <Box
                    sx={{
                      height: 300,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div> No Invoices Submitted yet</div>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashboardDailyReport;
