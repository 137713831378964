import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getUserId,
} from "../../comman/function";
import { makeStyles } from "@mui/styles";
import { UPDATE_PASSWORD, UPDATE_TWOFAAUTHENTICATION } from "../../comman/url";
import { authHeader } from "../../comman/mainfunction";
import API from "../../constants/API";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";
import "react-phone-number-input/style.css";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

import { PASSWORD_REGEX, NUMBER_REGEX } from "../../comman/constants";

const Input = styled("input")({
  display: "none",
});

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  verifybutton: {
    textTransform: "none !important",
    padding: "0px 0px 0px 16px !important",
    color: "#EC7C37 !important",
    fontWeight: "500px !important",
  },
  uploadbtn: {
    backgroundColor: "unset !important",
    border: "1px solid #3779EC !important",
    color: "#3779EC !important",
    boxShadow: "none !important",
  },
}));
function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative", marginRight: "5px" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={15}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          top: "10px",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={15}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
const SecurityProfile = (props) => {
  const { classes } = props;
  const styleclasses = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmnewPassword: "",
    // twoFaAuthenticationCode: "",
  });
  const [authData, setAuthData] = useState({
    twoFaAuthenticationCode: "",
  });
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [authPasswordShow, setAuthPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const oldPasswordToggle = () => setOldPasswordShow(!oldPasswordShow);
  const newPasswordToggle = () => setNewPasswordShow(!newPasswordShow);
  const confirmPasswordToggle = () =>
    setConfirmPasswordShow(!confirmPasswordShow);
  const authPasswordToggle = () => setAuthPasswordShow(!authPasswordShow);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Required"),
    newPassword: Yup.string()
      .test(
        "passwords-match",
        "Password should not be old password",
        function (value) {
          return this.parent.oldPassword !== value;
        }
      )

      .required("Required")
      .matches(
        PASSWORD_REGEX,
        "Invalid Password, Must contain one lowercase and one uppercase"
      )
      .min("6", "Invalid Password,Password must be longer than 6 characters"),
    confirmnewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
    // twoFaAuthenticationCode: Yup.string()
    //   // .required("Required")
    //   .matches(NUMBER_REGEX, "2FA Authentication code must be in number")
    //   .min("6", "Please enter the six digit code")
    //   .max("6", "Please enter only six digit code"),
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    resetForm,
    errors,
    touched,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UpdatePassword(values),
  });
  let UpdatePassword = async (values) => {
    console.log("Update:", values);
    values.email = getEmail();
    const config = {
      method: "PUT",
      url: UPDATE_PASSWORD,
      headers: authHeader(),
      //values,
      data: {
        webString: enCryptFun(JSON.stringify(values)),
        flutterString: "",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        resetForm({});
        CustomMessage("Update Successfully", "success", enqueueSnackbar); //call toster
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));

  // const handleFieldChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "newPassword") {
  //     setNewPasswordShow(false);
  //   }
  //   if (name === "oldPassword") {
  //     setOldPasswordShow(false);
  //   }
  //   if (name === "confirmnewPassword") {
  //     setConfirmPasswordShow(false);
  //   }
  //   if (name === "twoFaAuthenticationCode") {
  //     setAuthPasswordShow(false);
  //   }
  //   handleChange(e);
  // };
  const authValidationSchema = Yup.object().shape({
    twoFaAuthenticationCode: Yup.string()
      .required("Required")
      .matches(NUMBER_REGEX, "2FA Authentication code must be in number")
      .min("6", "Please enter the six digit code")
      .max("6", "Please enter only six digit code"),
  });
  const authHandleFieldChange = (e) => {
    const { name } = e.target;
    if (name === "twoFaAuthenticationCode") {
      setAuthPasswordShow(false);
    }
    authFormik.handleChange(e);
  };
  let UpdateTwoFAAuthenticationPassword = async (values) => {
    values.email = getEmail();
    const config = {
      method: "PUT",
      url: UPDATE_TWOFAAUTHENTICATION,
      headers: authHeader(),
      //values,
      data: {
        webString: enCryptFun(JSON.stringify(values)),
        flutterString: "",
      },
    };
    try {
      setAuthLoading(true);
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (status === "Success") {
        authFormik.resetForm({});
        CustomMessage("Update Successfully", "success", enqueueSnackbar); //call toster
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setAuthLoading(false);
    }
  };
  const authFormik = useFormik({
    initialValues: authData,
    enableReinitialize: true,
    validationSchema: authValidationSchema,
    onSubmit: (values) => UpdateTwoFAAuthenticationPassword(values),
  });

  return (
    <>
      <Box
        sx={{ maxWidth: 600, margin: "auto", mt: 4 }}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Typography
          gutterBottom
          sx={{ fontWeight: 600, color: "#4E4B66", textAlign: "left", pb: 2 }}
        >
          Change Your Password
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            pb: 2,
            fontSize: "14px",
            letterSpacing: "0px",
            color: "#A0A3BD",
          }}
        >
          An email will be sent to your account's registered email address.{" "}
        </Typography>

        <Box sx={{ mt: 1, textAlign: "left", display: "grid" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Current Password
            </Typography>

            <CssTextField
              fullWidth
              required
              id="outlined-adornment-password oldPassword"
              type={oldPasswordShow ? "text" : "password"}
              value={values.oldPassword}
              name="oldPassword"
              onChange={handleChange}
              // onChange={(e) => handleFieldChange(e)}
              onBlur={handleBlur}
              error={errors.oldPassword && touched.oldPassword}
              placeholder="Enter Current Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={oldPasswordToggle}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {oldPasswordShow == false ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              //   endAdornment={<InputAdornment position="end"></InputAdornment>}
            />

            <Typography
            // sx={{
            //   fontSize: "14px",
            //   letterSpacing: "0px",
            //   color: "#A0A3BD",
            //   pt: 1,
            // }}
            >
              {errors.oldPassword && touched.oldPassword
                ? errors.oldPassword
                : ""}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              New Password
            </Typography>
            <CssTextField
              fullWidth
              required
              id="outlined-adornment-password newPassword"
              type={newPasswordShow ? "text" : "password"}
              value={values.newPassword}
              onChange={handleChange}
              // onChange={(e) => handleFieldChange(e)}
              onBlur={handleBlur}
              name="newPassword"
              error={errors.newPassword && touched.newPassword}
              placeholder="Enter New Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={newPasswordToggle}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {newPasswordShow == false ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography
            // sx={{
            //   fontSize: "14px",
            //   letterSpacing: "0px",
            //   color: "#A0A3BD",
            //   pt: 1,
            // }}
            >
              {errors.newPassword && touched.newPassword
                ? errors.newPassword
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Confirm New Password
            </Typography>
            <CssTextField
              fullWidth
              required
              id="outlined-adornment-password confirmnewPassword"
              type={confirmPasswordShow ? "text" : "password"}
              value={values.confirmnewPassword}
              onChange={handleChange}
              // onChange={(e) => handleFieldChange(e)}
              onBlur={handleBlur}
              name="confirmnewPassword"
              error={errors.confirmnewPassword && touched.confirmnewPassword}
              placeholder="Confirm New Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={confirmPasswordToggle}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {confirmPasswordShow == false ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography
            // sx={{
            //   fontSize: "14px",
            //   letterSpacing: "0px",
            //   color: "#A0A3BD",
            //   pt: 1,
            // }}
            >
              {errors.confirmnewPassword && touched.confirmnewPassword
                ? errors.confirmnewPassword
                : ""}
            </Typography>
          </Grid>
        </Box>

        <Button
          sx={{
            mt: 3,
            backgroundColor: "#EC7C37",
            textTransform: "capitalize",
          }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          startIcon={
            <span style={{ display: "flex", alignItems: "center" }}>
              {loading && <FacebookCircularProgress />}
            </span>
          }
        >
          Save Changes
        </Button>
      </Box>
      {/* <Box
        sx={{ maxWidth: 600, margin: "auto", mt: 4 }}
        component={"form"}
        onSubmit={authFormik.handleSubmit}
      >
        <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ fontWeight: 600, color: "#4E4B66", display: "flex" }}
          >
            2FA Authentication Code
            <LightTooltip
              title="Security Authentication Code"
              placement="bottom-start"
            >
              <InfoOutlinedIcon
                style={{
                  color: "#A0A3BD",
                  width: "15px",
                  margin: "0px 6px",
                }}
              />
            </LightTooltip>
          </Typography>
          <CssTextField
            fullWidth
            // required
            id="outlined-adornment-password"
            type={authPasswordShow ? "text" : "password"}
            value={authFormik.values.twoFaAuthenticationCode}
            // onChange={handleChange}
            onChange={(e) => authHandleFieldChange(e)}
            onBlur={authFormik.handleBlur}
            placeholder="Enter 2FA Authentication Code"
            error={
              authFormik.errors.twoFaAuthenticationCode &&
              authFormik.touched.twoFaAuthenticationCode
            }
            name="twoFaAuthenticationCode"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={authPasswordToggle}
                    edge="end"
                  >
                    {authPasswordShow == false ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography sx={{ textAlign: "left" }}>
            {authFormik.errors.twoFaAuthenticationCode &&
            authFormik.touched.twoFaAuthenticationCode
              ? authFormik.errors.twoFaAuthenticationCode
              : ""}
          </Typography>
        </Grid>{" "}
        <Button
          sx={{
            mt: 3,
            backgroundColor: "#EC7C37",
            textTransform: "capitalize",
          }}
          variant="contained"
          type="submit"
          onSubmit={authFormik.handleSubmit}
          startIcon={
            <span style={{ display: "flex", alignItems: "center" }}>
              {authLoading && <FacebookCircularProgress />}
            </span>
          }
        >
          Save Changes
        </Button>
      </Box> */}
    </>
  );
};

export default SecurityProfile;
