import React, { useEffect, useState } from "react";
import { Box, Paper, Grid, Button, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { CustomMessage } from "../comman/helperfunction";
import { useSnackbar } from "notistack";
import { DASHBOARD_TEAM_STATISTICS } from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import {
  deCryptFun,
  enCryptFun,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
} from "../comman/function";
import API from "../constants/API";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function DashboardTeamStatictics(props) {
  const {
    teamMemberstat,
    invDistribution,
    aprvDistribution,
    amount,
    setTeamMemberstat,
  } = props;
  const {
    UserActionsPerDay,
    TeamMemberApprovals,
    TeamRoleDistribution,
    InvoiceApprovalWorkload,
    AvgDataEntryTimePerTeamMember,
  } = teamMemberstat;
  let chartWidth;
  const [roleDistribution, setroleDistribution] = useState();
  const [countroleDistri, setcountroleDistri] = useState();
  const [g23avgUserName, setg23avgUserName] = useState();
  const [g23avgcount, setg23avgcount] = useState();
  const [g24Name, setg24Name] = useState();
  const [g24count, setg24count] = useState();
  const [distributioncount, setDistributionCount] = useState();
  const [distributionAmt, setDistributionAmt] = useState();
  const [aprvName, setaprvName] = useState();
  const [g21pendingInvoices, setg21pendingInvoices] = useState(0);
  const [g21perApproved, setg21perApproved] = useState();
  // const [g21perException, setg21perException] = useState();
  const [g18data, setg18data] = useState();
  const [g18dayName, setg18dayName] = useState();
  const [analysisFilterChange, setAnalysisFilterChange] = useState("");
  const [invoiceFilterChange, setInvoiceFilterChange] = useState("");

  let previousyrdate = new Date().setFullYear(new Date().getFullYear() - 1);
  const [toDate, setToDate] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  let date = new Date()
  let date1 = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6: -3)
  const [filterState, setfilterState] = useState({
    // analysisDate: dateFormate(new Date()),
    fromDate:new Date(date.setDate(date1)),
    toDate: new Date(),
  });

  // const [filterState, setfilterState] = useState({
  //   fromDate: previousyrdate,
  //   toDate: new Date(),
  // });
  const [teamToggle, setTeamToggle] = useState(false);
  const getTeamMemberStatictics = async () => {
    setTeamToggle(true);
    let date = new Date();
    var fromActionDate =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    var toActionDate = fromActionDate + 6;
    let fromac = new Date(date.setDate(fromActionDate));
    let toacc = new Date(date.setDate(toActionDate));
    const config = {
      method: "GET",
      url: DASHBOARD_TEAM_STATISTICS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,
            // fromDate: filterState.fromDate,
            // toDate: moment(new Date()).format("YYYY-MM-DD"),
            fromDate: moment(filterState.fromDate).format("YYYY-MM-DD"),
            toDate: moment(filterState.toDate).format("YYYY-MM-DD"),
            userActionFromDate: fromac.toISOString().slice(0, 10),
            userActionToDate: toacc.toISOString().slice(0, 10),
            // senderEmail: getRole() === "Supplier" ? getEmail() : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const {
        status,
        UserActionsPerDay,
        TeamMemberApprovals,
        TeamRoleDistribution,
        InvoiceApprovalWorkloaddistribution,
        AvgDataEntryTimePerTeamMember,
        TeamRoleDistributioWithException,
      } = JSON.parse(l);
      console.log("Team role distri");
      let fitem;
      let fitemCount;
      if (TeamRoleDistribution) {
        fitem = TeamRoleDistribution.find(
          (rec) => rec.UserRole == "Team Member"
        );

        fitemCount = TeamRoleDistribution.find(
          (rec) => rec.UserRole == "Team Member"
        ).totalCount;
        TeamRoleDistribution.find(
          (rec) => rec.UserRole == "Team Member"
        ).totalCount =
          fitemCount - TeamRoleDistributioWithException[0].totalCount;
        console.log("Team role distri", fitem);
      }
      let temp = TeamRoleDistribution;
      if (
        TeamRoleDistributioWithException &&
        TeamRoleDistributioWithException[0].totalCount > 0
      ) {
        temp.push({
          totalCount: TeamRoleDistributioWithException[0].totalCount,
          UserRole: "Exception Handler",
        });
      }
      if (status === "Success") {
        setTeamMemberstat({
          UserActionsPerDay: UserActionsPerDay,
          TeamMemberApprovals: TeamMemberApprovals,
          TeamRoleDistribution: temp,
          InvoiceApprovalWorkload: InvoiceApprovalWorkloaddistribution,
          AvgDataEntryTimePerTeamMember: AvgDataEntryTimePerTeamMember,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setTeamToggle(false);
    }
  };
  useEffect(() => {
    // if (aprvName) {
    //   console.log(stableSort(aprvName, getSorting("asc", "userName")));
    // }
  }, [aprvName]);
  useEffect(() => {
    getTeamMemberStatictics();
  }, [filterState.fromDate, filterState.toDate]);
  console.log("distri", TeamRoleDistribution, roleDistribution);
  const teamMemberstatCall = () => {
    setroleDistribution(
      TeamRoleDistribution.map((rd) =>
        rd.UserRole === null ? "" : rd.UserRole
      )
    );
    setcountroleDistri(TeamRoleDistribution.map((rd) => rd.totalCount));
    setg23avgUserName(
      AvgDataEntryTimePerTeamMember.filter(
        (x) => x.userName !== "N/A" && x.userName !== null && x.userName !== ""
      ).map((avgtm) => avgtm.userName)
    );
    setg23avgcount(
      AvgDataEntryTimePerTeamMember.filter(
        (x) => x.userName !== "N/A" && x.userName !== null && x.userName !== ""
      ).map((avgtm) => avgtm.avgInvProcessingTime.toFixed(2))
    );
    setg24Name(
      TeamMemberApprovals.map((tma) =>
        tma.userName === null ? "" : tma.userName
      )
    );
     setg24count(TeamMemberApprovals.map((tma) => tma.totalCount));
    setDistributionCount(
      invDistribution.filter((x) => x.invoice !== 0).map((iv) => iv.invoice)
    );
    setDistributionAmt(
      invDistribution
        .filter((x) => x.invoice !== 0)
        .map((iv) =>
          iv.toAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )
    );

    // setaprvName(
    //   stableSort(aprvDistribution),
    //   getSorting("asc", "userName").map((ad) => ad.userName)
    // );

    const sortAprvDistributionData = () => {
      let aprvDistributionData = [...aprvDistribution]
      aprvDistributionData.sort((a, b) => {
        if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
          return 1;
        }
        if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return aprvDistributionData
    }
    setaprvName(sortAprvDistributionData().map((ad) => ad.userName));
    setg21perApproved(
      sortAprvDistributionData().map((ad) =>
        ((ad.InvoiceApprovedbytheUser / ad.ApprovedInvoiceUnderUserLimit) * 100).toFixed(2)
      )
    );
    // setg21perException(
    //   aprvDistribution.map((ad) =>
    //     ((ad.exception / ad.totalInvoiceCount) * 100).toFixed(2)
    //   )
    // );
    setg21pendingInvoices(
      sortAprvDistributionData().map((ad) =>
        (
          ((ad.ApprovedInvoiceUnderUserLimit-ad.InvoiceApprovedbytheUser)  / ad.ApprovedInvoiceUnderUserLimit) *
          100
        ).toFixed(2)
      )
    );

    //userAction
    const uniqueName = [
      ...new Set(UserActionsPerDay.map((name) => name.actionName)),
    ];
    const uniqueDayName = [
      ...new Set(UserActionsPerDay.map((name) => name.actionDay)),
    ];
    setg18dayName(uniqueDayName);
    const userfilter = uniqueName.map((d) =>
      UserActionsPerDay.filter((x) => x.actionName === d)
    );
    let rearranged = userfilter.map((val) => {
      let invcount = val.map((vv) => vv.actionCount);
      return invcount;
    });

    let objuserAction = rearranged.map((element, index) => {
      let properties = {
        data: element,
        name: uniqueName[index],
      };
      return properties;
    });
    console.log("user action", objuserAction);
    setg18data(objuserAction);
  };
  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }
  let randomFillColor = [];
  if (distributioncount) {
    for (var i = 0; i < distributioncount.length; i += 1) {
      randomFillColor.push(getRandomColor());
    }
  }
  let randomFillColor1 = [];
  if (g18data) {
    for (var i = 0; i < g18data.length; i += 1) {
      randomFillColor1.push(getRandomColor());
    }
  }

  let C18series = g18data;
  
  var C18options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },

    xaxis: {
      categories: g18dayName,
      // title: {
      //   text: "Day",
      //   style: {
      //     fontWeight: 600,
      //   },
      // },
    },
    yaxis: {
      forceNiceScale: true,

      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
      // max: (max) => {
      //   max = Math.max(...C18series[0]["data"]);
      //   if (max < 4) {
      //     return max + 2;
      //   } else {
      //     return max;
      //   }
      // },
      title: {
        text: "Number of Actions",
        floating: false,

        style: {
          fontWeight: 600,
        },
      },
    },
    fill: {
      opacity: 1,
      colors: randomFillColor1,
    },
    colors: randomFillColor1,
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
      legend: {
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    },
    // tooltip: {
    //   y: { title: { show: false, formatter: () => "" } },
    // },
    tooltip: {
      y: {
        title: { show: true },
        formatter: function (val) {
          return parseInt(val);
        },
        // title: {
        //   show: true,
        //   formatter: function (val) {
        //     return parseInt(val);
        //   },
        // },
      },
    },
  };
  
  let C21series = [
    {
      name: "Percentage of total invoices that can be approved based on approval limit",
      data: g21pendingInvoices,
    },
    {
      name: "Percentage of total invoices approved",
      data: g21perApproved,
    },
    // {
    //   name: "Percentage of Total Invoices Exception",
    //   data: g21perException,
    // },
  ];
 
  var C21options = {
    chart: {
      type: "bar",
      height: 400,

      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
     
      categories: aprvName,
    },
    yaxis: {
      max: 100,
      tickAmount: 5,
     
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " %";
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  let C22series = distributioncount;
  let C22options = {
    chart: {
      width: 380,
      type: "pie",
    },
    fill: {
      colors: randomFillColor,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    fill: {
      colors: [function({ value,seriesIndex, w }) {
        console.log("colors>",seriesIndex, w);
        if (seriesIndex == 0 ) {
            return '#d41815'
        } else if(seriesIndex == 1 ) {
            return '#d4ca15'
        } else if(seriesIndex == 2 ) {
          return '#0bb30b'
        }else if(seriesIndex == 3 ) {
          return '#10bbc7'
        }else  {
          return '#ad09a8'
      }
      }],
    },
    colors: [function({ value,seriesIndex, w }) {
      console.log("colors>",seriesIndex, w);
      if (seriesIndex == 0 ) {
          return '#d41815'
      } else if(seriesIndex == 1 ) {
          return '#d4ca15'
      } else if(seriesIndex == 2 ) {
        return '#0bb30b'
      }else if(seriesIndex == 3 ) {
        return '#10bbc7'
      }else  {
        return '#ad09a8'
    }
    }],

    labels: distributionAmt,
  };
  //TeamroleDistribution
  let C20series = [
    {
      data: countroleDistri,
    },
  ];
  var C20options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return parseInt(val);
        },
        title: {
          show: false,
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
    },

    // dataLabels: {
    //   enabled: true,
    //   style: {
    //     colors: ["#fff"],
    //   },
    //   formatter: function (val, opt) {
    //     return "";
    //   },
    // },
    yaxis: {
      title: {
        text: "Number of Users",
        floating: false,

        style: {
          fontWeight: 600,
        },
      },
      forceNiceScale: true,

      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
      max: (max) => {
        max = Math.max(...C20series[0]["data"]);
        if (max < 4) {
          return max + 2;
        } else {
          return max;
        }
      },
    },

    xaxis: {
      tickPlacement: "on",
      
      // labels: {
      //   rotate: 0,
      //   formatter: function (val) {
      //     return val.toFixed(0);
      //   },
      // },

      type: "category",
      categories: roleDistribution,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },

    fill: {
      opacity: 1,
    },
  };
  var C23series = [
    {
      data: g23avgcount,
    },
  ];
  var C23options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: { title: { show: false, formatter: () => "" } },
    },

    dataLabels: {
      enabled: false,
      style: {
        colors: ["#fff"],
      },
      formatter: function (val) {
        return val.toFixed(4);
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
        dataLabels: {
          position: "bottom",
          
        },
      },
    },
    xaxis: {
      
      tickPlacement: "on",
      labels: {
        showDuplicates: false,
        rotate: 0,
        formatter: function (val) {
          console.log(val,"val")
          return val.toFixed(0);
        },
      },

      type: "category",
       categories: g23avgUserName,
      title: {
        text: "Number of Minutes",
        style: {
          fontWeight: 600,
        },
      },
      
    },
    noData: {
      text: "No invoices",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
    yaxis: {
      
      formatter: function (val) {
        return parseInt(val);
      },
      max: (max) => {
        max = Math.max(...C23series[0]["data"]);
        if (max < 4) {
          return "4";
        } else {
          return max;
        }
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    

    fill: {
      opacity: 1,
    },
  };

  
  console.log(C23series[0]["data"]>2,"C23series")
  var C24options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    // tooltip: {
    //   y: { title: { show: false, formatter: () => "" } },
    // },
    tooltip: {
      y: {
        formatter: function (val) {
          return parseInt(val);
        },
        title: {
          show: false,
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return "";
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 10,
      },
    },
    xaxis: {
      tickAmount: 1,
      tickPlacement: "on",
      labels: {
        rotate: 0,
        formatter: function (val) {
          return val.toFixed(0);
        },
      },

      type: "category",
      categories: g24Name,
      title: {
        text: "Number of Invoices",
        style: {
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    fill: {
      opacity: 1,
    },
  };
  var C24series = [
    {
      data: g24count,
    },
  ];

  useEffect(() => {
    if (teamMemberstat && invDistribution && aprvDistribution) {
      teamMemberstatCall();
    }
  }, [teamMemberstat, invDistribution, aprvDistribution]);
  const dateFormate = (date) => {
    return moment(date).utc().format("YYYY-MM-DD");
  };
  const dateFormate1 = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  const dateFormateyear = (date) => {
    return moment(date).format("YYYY");
  };
  const dateFormateMonth = (date) => {
    return moment(date).format("MM");
  };
  const dateFormatDate = (date) => {
    return moment(date).format("DD");
  };
  const maxDateFunction = (e) => {
    if (toDate != null && toDate != "Invalid Date") {
      return new Date(filterState.toDate);
    } else {
      return new Date();
    }
  };
  let oneWeekAgo = new Date();
  let minbeforDate = oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [fromFlag, setFlag] = useState(false);
  // const dateHandlechange2 = (name, value) => {
  //   let lfin = dateFormate1(new Date(value));

  //   if (lfin.length == 10 && new Date(value) !== "Invalid Date") {
  //     console.log("set handle change to");
  //     if (
  //       (name == "toDate" &&
  //         dateFormate1(new Date(value)) >=
  //           dateFormate(new Date().setFullYear(new Date().getFullYear() - 1)) &&
  //         dateFormate1(new Date(value)) <= dateFormate1(new Date())) ||
  //       new Date(value) == new Date(filterState.fromDate)
  //     ) {
  //       setfilterState((v) => ({ ...v, [name]: value }));

  //       return null;
  //     } else if (
  //       dateFormateyear(value) > "0001" &&
  //       dateFormateyear(value) < "0999"
  //     ) {
  //       console.log("date format year");
  //       return null;
  //       // CustomMessage("Invalid year", "error", enqueueSnackbar);
  //     } else {
  //       CustomMessage("Invalid Date", "error", enqueueSnackbar);
  //       setfilterState((v) => ({ ...v, [name]: new Date() }));

  //       return null;
  //     }
  //   }
  //   //  else {
  //   //   return null;
  //   // }
  //   if (name == "analysisDate") {
  //     setAnalysisFilterChange("Invoice Analysis Filter is changed");
  //   } else {
  //     setInvoiceFilterChange("Invoice Filter is changed");
  //   }
  //   setfilterState((v) => ({ ...v, [name]: value }));
  // };
   const invalidate = (e) => {
    // return null
    // console.log(e);
  };
  const getLast7Days = (X) => {
    let dates = [];
    let getBefore7Days = [] 
    for (let I = 0; I < Math.abs(X); I++) {
        dates.push(new Date(new Date().getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).toLocaleString());
    }
    getBefore7Days = dates.map((data)=>data.split('/')[1])
    return getBefore7Days
}

const dateHandlechange1 = (name, value) => {
  let lfin = dateFormate1(new Date(value));
  console.log("l length", lfin.length, name, value, new Date(value));
  if (lfin.length == 10 && new Date(value) !== "Invalid Date") {
    console.log("set");
    if (name == "fromDate") {
      if (
        // name == "fromDate" &&
        dateFormate1(new Date(value)) >=
          dateFormate(new Date().setFullYear(new Date().getFullYear() - 1)) &&
        dateFormate1(new Date(value)) <= dateFormate1(new Date())
      ) {
        console.log("set one");
        setfilterState((v) => ({ ...v, [name]: value }));
        console.log("set 2");

        return null;
      } else if (
        dateFormateyear(value) > "0001" &&
        dateFormateyear(value) < "0999"
      ) {
        console.log("date format year");

        return null;
        // CustomMessage("Invalid year", "error", enqueueSnackbar);
      } else if (dateFormateyear(value) == "000") {
        console.log("invalid one");
        CustomMessage(
          "Invalid date. Please select the date with in past one year.",
          "error",
          enqueueSnackbar
        );
        // setfilterState((v) => ({ ...v, [name]: previousyrdate }));
      } else {
        CustomMessage(
          "Invalid date. Please select the date with in past one year.",
          "error",
          enqueueSnackbar
        );
        setfilterState((v) => ({ ...v, [name]: previousyrdate }));

        return null;
      }
    }
  } else {
    console.log(
      "chk fin",
      dateFormateyear(value),
      dateFormateyear(value) == "0000"
    );
     CustomMessage("Invalid month.Please enter valid month.", "error", enqueueSnackbar);
    return null;
  }

  if (name == "analysisDate") {
    setAnalysisFilterChange("Invoice Analysis Filter is changed");
  } else {
    setInvoiceFilterChange("Invoice Filter is changed");
  }
  setfilterState((v) => ({ ...v, [name]: value }));
};


const dateHandlechange2 = (name, value) => {
  let lfin = dateFormate1(new Date(value));

  if (lfin.length == 10 && new Date(value) !== "Invalid Date") {
    console.log("set handle change to");
    if (
      (name == "toDate" &&
        dateFormate1(new Date(value)) >=
          dateFormate(new Date().setFullYear(new Date().getFullYear() - 1)) &&
        dateFormate1(new Date(value)) <= dateFormate1(new Date())) ||
      new Date(value) == new Date(filterState.fromDate)
    ) {
      setfilterState((v) => ({ ...v, [name]: value }));

      return null;
    } else if (
      dateFormateyear(value) > "0001" &&
      dateFormateyear(value) < "0999"
    ) {
      console.log("date format year");
      return null;
      // CustomMessage("Invalid year", "error", enqueueSnackbar);
    } else {
      CustomMessage("Invalid Date", "error", enqueueSnackbar);
      setfilterState((v) => ({ ...v, [name]: new Date() }));

      return null;
    }
  }
  //  else {
  //   return null;
  // }
  if (name == "analysisDate") {
    setAnalysisFilterChange("Invoice Analysis Filter is changed");
  } else {
    setInvoiceFilterChange("Invoice Filter is changed");
  }
  setfilterState((v) => ({ ...v, [name]: value }));
};

  const dateHandlechange = (name, value) => {
    const last7Days = getLast7Days(8)
    let lfin = dateFormate1(new Date(value));
    if (lfin.length == 10 && new Date(value) !== "Invalid Date" ) {
      if(dateFormateMonth(value) !== dateFormateMonth(new Date()) || dateFormateyear(value) !== dateFormateyear(new Date()) || !last7Days.includes(dateFormatDate(value))){
        CustomMessage(
          "Invalid date. Please select the date with in past one year.",
          "error",
          enqueueSnackbar
        );
        setfilterState((v) => ({ ...v, [name]: new Date() }))
      } else{
        setfilterState((v) => ({ ...v, [name]: value }))
      }
      
      // console.log("set");
      // if (name == "fromDate") {
      //   if (
      //     // name == "fromDate" &&
      //     dateFormate1(new Date(value)) >=
      //       dateFormate(new Date().setFullYear(new Date().getFullYear() - 1)) &&
      //     dateFormate1(new Date(value)) <= dateFormate1(new Date())
      //   ) {
      //     console.log("set one");
      //     setfilterState((v) => ({ ...v, [name]: value }));
      //     console.log("set 2");

      //     return null;
      //   } else if (dateFormateMonth(value) === "Invalid date") {
      //     CustomMessage(
      //       "Invalid date///. Please select the date with in past one year.",
      //       "error",
      //       enqueueSnackbar
      //     );
      //     // setfilterState((v) => ({ ...v, [name]: previousyrdate }));
      //   }
      //   else if (
      //     dateFormateyear(value) > "0001" &&
      //     dateFormateyear(value) < "0999"
      //   ) {
      //     console.log("date format year");

      //     return null;
      //     // CustomMessage("Invalid year", "error", enqueueSnackbar);
      //   } else if (dateFormateyear(value) == "000") {
      //     console.log("invalid one");
      //     CustomMessage(
      //       "Invalid date. Please select the date with in past one year.",
      //       "error",
      //       enqueueSnackbar
      //     );
      //     // setfilterState((v) => ({ ...v, [name]: previousyrdate }));
      //   } else {
      //     CustomMessage(
      //       "Invalid date. Please select the date with in past one year.",
      //       "error",
      //       enqueueSnackbar
      //     );
      //     setfilterState((v) => ({ ...v, [name]: minbeforDate }));

      //     return null;
      //   }
      // }
    } else {
      console.log(
        "chk fin",
        dateFormateyear(value),
        dateFormateyear(value) == "0000"
      );
      CustomMessage(
              "Invalid date. Please select the date with in past one year.",
              "error",
              enqueueSnackbar
            );
      // CustomMessage("Invalid Date fin", "error", enqueueSnackbar);
      return null;
    }

    if (name == "analysisDate") {
      setAnalysisFilterChange("Invoice Analysis Filter is changed");
    } else {
      setInvoiceFilterChange("Invoice Filter is changed");
    }
    setfilterState((v) => ({ ...v, [name]: value }));
  };
  // const dateHandlechange = (name, value) => {
  //   let lfin = dateFormate1(new Date(value));
  //   if (lfin.length == 10) {
  //     if (
  //       name == "fromDate" &&
  //       dateFormate1(new Date(value)) !=
  //         dateFormate(new Date().setFullYear(new Date().getFullYear() - 1))
  //     ) {
  //       if (
  //         (name == "fromDate" &&
  //           dateFormate1(new Date(value)) <
  //             dateFormate(
  //               new Date().setFullYear(new Date().getFullYear() - 1)
  //             )) ||
  //         dateFormate1(new Date(value) > dateFormate1(new Date()))
  //       ) {
  //         if (
  //           (dateFormateyear(value) > "0001" &&
  //             dateFormateyear(value) < "0999") ||
  //           dateFormate1(new Date(value)) === dateFormate1(new Date())
  //         ) {
  //           return null;
  //         } else {
  //           setFlag(true);
  //           CustomMessage("Invalid Date", "error", enqueueSnackbar);
  //           return null;
  //         }
  //       }
  //     }
  //     //to date
  //     if (
  //       (name == "toDate" && dateFormate1(new Date(value)) < previousyrdate) ||
  //       dateFormate1(new Date(value)) > dateFormate1(new Date())
  //     ) {
  //       if (
  //         (dateFormateyear(value) > "0001" &&
  //           dateFormateyear(value) < "0999") ||
  //         dateFormate1(new Date(value)) === dateFormate1(new Date())
  //       ) {
  //         return null;
  //       } else {
  //         CustomMessage("Invalid Date", "error", enqueueSnackbar);
  //         return null;
  //       }
  //     }
  //     if (value == "Invalid Date") {
  //       CustomMessage("Invalid Date", "error", enqueueSnackbar);
  //       return null;
  //     }
  //     let d1 = new Date(filterState.fromDate);
  //     let d2 = new Date(value);
  //     let sf =
  //       moment(new Date(value)).format("YYYY-MM-DD") ==
  //       moment(new Date(filterState.fromDate)).format("YYYY-MM-DD");
  //     console.log("d1", d1, d2, sf && fromFlag);
  //     if (
  //       (name == "toDate" &&
  //         new Date(value) < new Date(filterState.fromDate)) ||
  //       new Date(value) == new Date(filterState.fromDate)
  //     ) {
  //       console.log(
  //         "filter from date",
  //         new Date(filterState.fromDate),
  //         dateFormateyear(value)
  //       );
  //       console.log("aprvName");
  //       if (dateFormateyear(value) < "0999") {
  //         return null;
  //       }

  //       console.log("year to value", dateFormateyear(value) <= "0999");
  //       if (
  //         dateFormateyear(value) < "0999" ||
  //         sf ||
  //         dateFormate1(new Date(value)) === dateFormate1(new Date())
  //       ) {
  //         return null;
  //       } else {
  //         CustomMessage(
  //           "To Date is Invalid/Should be greater than From Date ",
  //           "error",
  //           enqueueSnackbar
  //         );
  //         return null;
  //       }
  //     }
  //   }
  //   if (name == "analysisDate") {
  //     setAnalysisFilterChange("Invoice Analysis Filter is changed");
  //   } else {
  //     setInvoiceFilterChange("Invoice Filter is changed");
  //   }
  //   setfilterState((v) => ({ ...v, [name]: value }));
  // };

  return (
    <Grid container sx={{ mb: 2 }}>
      {/* Container */}
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Number of User Actions, Per Day
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              
            }}
            spacing={2}
          >
            <Box sx={{textAlign:"right"}}>
              <Tooltip sx={{ mt: "0px !important" }} title="Shows the number of actions performed by each user in your organization day-to-day, correlating to overall productivity. An action is considered any item which is logged in the audit trail history of an invoice (edit, update, approve, open/resolve exceptions).">
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
        </Box>
      
            {randomFillColor1.length > 0 ? (
              randomFillColor1 && g18dayName && g18data ? (
                <Box
                  sx={{
                    height: "350px",
                  }}
                >
                  
                  <ReactApexChart
                    options={C18options}
                    series={C18series}
                    type="bar"
                    height="300"
                  />
                </Box>
              ) : (
                "No data"
              )
            ) : (
              <Box
                sx={{
                  height: "150px",

                  marginTop: "8rem",
                }}
              >
                <div>No user actions today</div>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Team Role Distribution
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
          
            }}
            spacing={2}
          >
            <Box
              sx={{
                height: "350px",
              }}
            >
                <Box sx={{textAlign:"right"}}>
               <Tooltip sx={{ mt: "0px !important" }} title=" Shows the number of users in each role in your organization.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip></Box>
              {roleDistribution && countroleDistri && (
                <ReactApexChart
                  options={C20options}
                  series={C20series}
                  type="bar"
                  height="300"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* End of Container */}
      {/* c23 */}
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Average Data Entry Time Per Team Member
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
              minHeight: "350px",
              maxHeight: "350px",
              overflowY: "auto",
              overflowX: "hidden",
              textAlign:"end"
              
            }}
            spacing={2}
          >
            <Box
                sx={{
                 textAlign:"right"
                }}
              > <Tooltip sx={{ mt: "0px !important" }} title="Provides the average number of minutes it takes each user in your organization to complete the data entry process on an invoice. This is calculated by utilizing the time in between edit/update actions in the audit trail history of an invoice.">
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip></Box>
            {g23avgcount && g23avgUserName && g23avgcount.length > 0 ? (
              <Box
                sx={{
                  height: "300px",
                }}
              >
               
                <ReactApexChart
                  options={C23options}
                  series={C23series}
                  type="bar"
                  height={
                    g23avgcount.length <= 4 ? "300" : g23avgcount.length * 60
                  }
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "150px",

                  marginTop: "8rem",
                }}
              >
                <div>No invoices yet</div>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* c23 */}

      {/* c24 */}
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Team Member Approvals
          </Typography>
</Grid>
          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
              minHeight: "350px",
              maxHeight: "350px",
              overflowY: "auto",
              overflowX: "hidden",
          
            }}
            spacing={2}
          >
            
            <Grid
                item
                xs={12}
                md={12}
                sx={{ zIndex: "0", display: "flex" }}
                justifyContent="right"
              >
              
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                  sx={{ display: "flex", flexDirection: "row", float: "right" }}
                  spacing={3}
                >
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={filterState.fromDate}
                  minDate={previousyrdate}
                  maxDate={maxDateFunction()}
                  allowSameDateSelection
                  onChange={(e) => dateHandlechange1("fromDate", e)}
                  // onChange={(e) => {
                  //   console.log(e);
                  // }}
                  onError={(e) => console.log(e)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{ width: "160px !important", mr: 2 }}
                    />
                  )}
                />
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={filterState.toDate}
                  allowSameDateSelection
                  onChange={(e) => {
                    setToDate(e);
                    dateHandlechange2("toDate", e);
                  }}
                  minDate={new Date(filterState.fromDate)}
                  maxDate={new Date()}
                  onError={invalidate()}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        marginTop: "0px !important",
                        width: "160px !important",
                        marginRight: "16px !important",
                        height: "0.5375em",
                      }}
                    />
                  )}
                />
                 <Tooltip sx={{ mt: "0px !important" }} title="Shows the number of invoices approved by each user within the date range specified.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                </Stack>
              </LocalizationProvider>
             
            </Grid>
          

            {g24Name && g24count && g24Name.length > 0 ? (
              <Box
                sx={{
                  height: "300px",
                }}
              >
                <ReactApexChart
                  options={C24options}
                  series={C24series}
                  type="bar"
                  height={g24Name.length <= 4 ? "300" : g24Name.length * 60}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "150px",

                  marginTop: "8rem",
                }}
              >
                <div>No Approved invoices yet</div>
              </Box>
            )}
          </Grid>
        
      </Grid>
      {/* c24 */}

      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Distribution of Invoice Totals
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              width: "100%",
             
            }}
            spacing={2}
          >
            <Box sx={{textAlign:"right"}}>
            <Tooltip sx={{ mt: "0px !important" }} title="This graph utilizes the approval limits set for users under ‘My Team’, to define the value ranges found in the legend. Utilize this pie chart to understand how many invoices users can approve in your organization, and how many are being routed for approvals.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
            </Box>
            {randomFillColor.length > 0 ? (
              randomFillColor &&
              distributionAmt && (
                <Box
                  sx={{
                    height: "350px",
                  }}
                >
                  
                  <ReactApexChart
                    options={C22options}
                    series={C22series}
                    type="pie"
                    height="300"
                  />
                </Box>
              )
            ) : (
              <Box
                sx={{
                  height: "150px",

                  marginTop: "8rem",
                }}
              >
                <div>No invoices</div>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5 }} container>
        <Grid item sx={{ pr: 3 }} xs={12} md={12} justifyContent="left">
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "600", mt: 3.7 }}
          >
            Invoice Approval Workload Distribution
          </Typography>

          <Grid
            xs={12}
            md={12}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#fff",
              borderRadius: "16px",
              boxShadow: "2px 2px 9px #0000001c",
              zIndex: "0 !important",
              minWidth: "1200px",
              maxWidth: "1200px",
           
              textAlign:"end"
            }}
            spacing={2}
          >
              <Tooltip sx={{ mt: "0px !important" }} title="Shows the distribution of invoice approvals for each user in your organization. Utilize this graph in conjunction with other graphs, such as “Distribution of Invoice Totals” to understand how many invoices are being routed for approval and the approval workload for each user.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
            <Grid sx={{ overflowX: "scroll !important"}}>
                       
            <Box
              sx={{
                height: "400px",
                width: "1200px",
              }}
            >
              {aprvName ? (
                <ReactApexChart
                  options={C21options}
                  series={C21series}
                  type="bar"
                  height="350"
                  width={aprvName.length <= 12 ? "100%" : aprvName.length * 70}
                />
              ) : (
                "No Data"
              )}
            </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashboardTeamStatictics;
