import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Axios from "axios";
import LineList from "./InvoiceLine/List";
import PoNonpo from "./PoNonpo";
import Uploadinvoice from "./Modal/Uploadinvoice";
import { FormControlLabel, Radio, RadioGroup, Select } from "@mui/material";
import { Form } from "reactstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { CSVLink, CSVDownload } from "react-csv";
import chaticon from "../images/chat white.png";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  INVOICE_DETAILS_NEW_ROUTE,
  INVOICE_DETAILS_ROUTE,
  INVOICE_ROUTE,
  INVOICEPREVIEW_ROUTE,
  UPLOAD_INVOICE_ROUTE,
} from "../constants/RoutePaths";
import ExceptionHandlerModel from "./Modal/ExceptionHandlerModel";

import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import InvoiceTable from "../Table/InvoiceTable";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import CropFreeIcon from "@mui/icons-material/CropFree";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { TextField } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { Document, Page, pdfjs } from "react-pdf";
import AuditLog from "./AuditLog";
import ChatSection from "./ChatSection";
import Avatar from "@mui/material/Avatar";
import Preview from "./preview";
import NotifySupplier from "./NotifySupplier";
import NotifyApprover from "./Modal/NotifyApprover";
import SupplierList from "./Modal/SupplierList";
import jsPDF from "jspdf";
import "jspdf-autotable";

import Swal from "sweetalert2";
import {
  Link,
  useParams,
  useHistory,
  Prompt,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  GET_INVOICE_DETAILS,
  UPDATE_INVOICE_STATUS,
  UPDATE_INVOICE,
  NEXT_INVOICE_URL,
  LOCK_INVOICE_URL,
  UNLOCK_INVOICE_URL,
  GET_INVOICE_FIELDS,
  ACCESS_S3_FILE,
  GET_AUDIT_URL,
  GET_USER_BY_ID,
  COMMENT_LIST_URL,
  GET_FORMAT_SETTING_URL,
  TRAINING_DATASET_URL,
  SAVE_TRAINING_DATASET_URL,
  TRAINING_DATA_STORAGE_URL,
  GET_TRAINED_DATASET_URL,
  EXTRACTION_FORM_URL,
  GET_ASSIGNED_GLCODE_VALUE,
  UPDATE_EXCEPTION_STATUS,
  TEAM_MEMBER_ASSIGN,
  GET_KEYVALUES_BY_SUPPLIERNAME,
  GET_ASSIGNER_LIST,
  GET_INVOICE_EXCEPTION_LIST,
  CREATE_COMMENT_URL,
  GET_SUPPLIER_LIST_URL,
  GET_SUPPLIER_LIST,
} from "../comman/url";

import {
  NUMBERS_AND_SLASH_SYMBOLS_REGEX,
  NUMBERS_ONLY_REGEX,
  TIME_MMDDYYYY_REGEX,
  VALID_DATE_DDMMYYYY_REGEX,
  VALID_DATE_MMDDYYYY_REGEX,
  CURRENCY_REGEX,
} from "../comman/constants";

import { authHeader, getAlert, getAlertToast } from "../comman/mainfunction";
import {
  deCryptFun,
  enCryptFun,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
  getApprovalAmount,
  getEmail,
  getIsExceptionRole,
} from "../comman/function";
import API from "../constants/API";
import moment from "moment";
import Badge from "@mui/material/Badge";

import { useDispatch, useSelector } from "react-redux";
import { UPDATE_FORMDATA, TURN_FLAG } from "../redux/action/ActionType";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  tablerow: {
    background: "black !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
    borderRadius: "8px",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    color: "#14142A !important",
    padding: "4px !important",
  },
  validationrow: {
    display: "flex",
    paddingBottom: "8px !important",
    // paddingLeft: "0px !important",
  },
  correctbutton: {
    margin: "15px 8px !important",
    padding: "0px",
    backgroundColor: "#CCF1EA !important",
    color: "#0E9F85 !important",
    boxShadow: "none",
    minWidth: 36,
    height: 35,
  },
  infobutton: {
    margin: "15px 8px !important",
    padding: "0px",
    backgroundColor: "#4E4B6615 !important",
    color: "#A0A3BD !important",
    boxShadow: "none",
    minWidth: 36,
    height: 35,
  },
  duplicatebutton: {
    margin: "15px 8px !important",
    padding: "0px",
    backgroundColor: "#FFEDEB !important",
    color: "#9C1401 !important",
    boxShadow: "none",
    minWidth: 36,
    height: 35,
  },
  cancelbutton: {
    margin: "15px 8px !important",
    padding: "0px",
    backgroundColor: "#F14A4A15 !important",
    color: "#F14A4A !important",
    boxShadow: "none",
    minWidth: 36,
    height: 35,
  },
  startICon: {
    marginRight: "0px !important",
    "& .MuiSvgIcon-root": {
      fontSize: "25px !important",
      paddingLeft: "2px",
    },
  },
  previewICon: {
    "& .MuiSvgIcon-root": {
      fontSize: "25px !important",
    },
  },
  soucebutton: {
    fontWeight: "bold !important",
    fontSize: "13px !important",
    margin: "4px 6px !important",
    textTransform: "none !important",
  },
  notifyapproverbtn: {
    margin: "0px 8px !important",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    color: "#3779EC !important",
    border: "2px solid #3779EC !important",
    textTransform: "none !important",
    lineHeight: "15px",
    height: "44px",
  },
  notifysupplierbtn: {
    margin: "0px 8px !important",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    color: "#EC7C37 !important",
    border: "2px solid #EC7C37 !important",
    textTransform: "none !important",
    lineHeight: "15px",
    height: "44px",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    fontSize: "12px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    lineHeight: "15px",
    height: "45px",
    border: "none",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
  invoicebody: {
    backgroundColor: "white !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    borderRadius: "15px",
  },
  previewbtn: {
    color: "#14142A !important",
  },
  arrowleft: {
    marginRight: "20px",
    color: "#2B88F0",
    // cursor: "pointer",
    position: "relative",
    zIndex: "100000",
    top: "2px",
  },
  arrowright: {
    marginLeft: "20px",
    color: "#2B88F0",
    cursor: "pointer",
    position: "relative",
    top: "2px",
  },
  headerleft: {
    paddingLeft: "14px !important",
    display: "flex",
    alignItems: "baseline",
  },
  refreshbtn: {
    color: "#EC7C37 !important",
    border: "1px solid #EC7C37 !important",
    minWidth: "unset !important",
  },
  invoicedata: {
    background: "#F0F1F5 !important",
    padding: "1rem !important",
    borderRadius: "10px 10px 0px 0px !important",
    display: "flex !important",
    color: "#14142A !important",
  },
  invoiceformdata: {
    display: "flex",
    border: "1px solid #F0F1F5 !important",
    padding: "1rem !important",
  },
  labelfont: {
    fontSize: "14px !important",
  },
  audittype: {
    fontSize: "18px !important",
    color: "#14142A !important",
    textAlign: "left",
    padding: " 4px 8px",
  },
  auditdate: {
    color: "#A0A3BD !important",
  },
  tools: {
    position: "absolute",
    zIndex: 9,
    top: 0,
    right: 0,
  },
  tamemo: {
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "15px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  },
  tabsmemo: {
    borderBottom: getRole() != "Supplier" ? "1px solid #e8e8e8" : "",
    "& .MuiTabs-indicator": {
      backgroundColor: "#4A9EF1",
    },
  },
  paper: {
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: "10px",
  },
  tooltipmemo: {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#14142A",
      boxShadow: "0px 3px 6px #00000029",
      fontSize: 14,
      fontWeight: 500,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      fontSize: 20,
      "&:before": {
        border: "1px solid #E6E8ED",
      },
    },
  },
}));

const AntTabsmemo = styled(Tabs)({
  borderBottom: getRole() != "Supplier" ? "1px solid #e8e8e8" : "",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});

const AntTabs = React.memo(AntTabsmemo);
const AntTabMemo = styled((props) => {
  return <Tab disableRipple {...props} />;
})(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.85)",
  fontSize: "18px !important",
  fontFamily: ["Montserrat"].join(","),
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#14142A",
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

const LightTooltip = styled(({ className, ...props }) => {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#14142A",
    boxShadow: "0px 3px 6px #00000029",
    fontSize: 14,
    fontWeight: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

// const ItemMemo = Paper)(({ theme }) => {
//   console.log("val");
//   return {
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   };
// });
const Item = React.memo(Paper);
export default function InvoiceSinglePreview(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { classes } = props;
  const styleclasses = useStyles();
  const [value, setValue] = useState(0);
  const [datavalue, setDataValue] = useState(0);
  const [formValues, setFormValues] = useState({});
  const { state } = useLocation();
  const { locationChange } = state || {};

  // const formValues = useSelector((st) => st.Invoiceformdata);
  const [subTeamId, setSubTeamId] = useState();
  let { invoiceID } = useParams();
  const [jobId, setJobId] = useState();
  const [emailbody, setMessage] = useState("");
  const [type, setType] = useState("po");
  const [previewUrl, setPreviewUrl] = useState("");
  const [requiredFieldList, setRequiredFieldList] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [Toggle, setToggle] = useState(false);
  const [GlCode, setGlCode] = useState("");
  const [detectedTableData, setDetectedTableData] = useState({});
  const [loadingdetect, setDetectLoading] = useState(false);
  const [detectedData, setDetectedData] = useState({});
  const [dynamicVal, setDynamicVal] = useState([]);
  const [currentInvoiceKeyPair, setCurrentInvoiceKeyPair] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const [rejectLoading, setRejectLoading] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState(0);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [keyData, setKeyData] = useState([]);
  // const [locationChange, setLocationChange] = useState(0);
  const [date, setDate] = useState(VALID_DATE_MMDDYYYY_REGEX);
  const dateFormat = "MM/dd/yyyy";
  const [approveLoading, setApproveLoading] = useState(false);
  const [keyChangeFlag, setKeyFlag] = useState(false);
  const [isEditLines, setIsEditLines] = useState(false);
  const isEdit = useSelector((s) => s.EditReducer);
  const [dateShow, setDateShow] = useState(false);
  const [updateKey, setUpdateKey] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabsec, setActiveTabsec] = useState("1");
  const [moveStatus, setMoveStatus] = useState(false);
  const [nextData, setnextdata] = useState(false);
  const [raisedTo, setraisedTo] = useState([]);
  let navigate = useNavigate();
  const TeamReducer = useSelector((s) => s.TeamReducer);
  localStorage.setItem("INVOICE_ID", invoiceID);
  const [dueDateError, setDueDateError] = useState(false);
  const [invoiceDateError, setInvoiceDateError] = useState(false);
  const [invoiceFilePath, setInvoiceFilePath] = useState("");
  const [statusOnLoad, setStatusOnLoad] = useState("");
  const [InvNum, setInvNum] = useState("");
  const [fieldArray, setFieldArray] = useState([
    {
      fieldName: "",
      targetVariable: "invoiceNumber",
    },
    {
      fieldName: "",
      targetVariable: "dueDate",
    },
    {
      fieldName: "",
      targetVariable: "invoiceAmount",
    },
    {
      fieldName: "",
      targetVariable: "dueAmount",
    },
    {
      fieldName: "",
      targetVariable: "orderNumber",
    },
    {
      fieldName: "",
      targetVariable: "invoiceDate",
    },
    {
      fieldName: "",
      targetVariable: "taxTotal",
    },
    {
      fieldName: "",
      targetVariable: "quantity",
    },
    {
      fieldName: "",
      targetVariable: "unitOfMeasure",
    },
    {
      fieldName: "",
      targetVariable: "unitPrice",
    },
    {
      fieldName: "",
      targetVariable: "operatingUnit",
    },
    {
      fieldName: "",
      targetVariable: "glAccount",
    },
    {
      fieldName: "",
      targetVariable: "GLDate",
    },
    {
      fieldName: "",
      targetVariable: "extendedPrice",
    },
    {
      fieldName: "",
      targetVariable: "itemDescription",
    },
    {
      fieldName: "",
      targetVariable: "poLineNumber",
    },
    {
      fieldName: "",
      targetVariable: "poNumber",
    },
    {
      fieldName: "",
      targetVariable: "invoiceLineType",
    },
    {
      fieldName: "",
      targetVariable: "invoiceLineNumber",
    },
    {
      fieldName: "",
      targetVariable: "itemNumber",
    },
  ]);
  console.log(fieldArray, "fieldArray");

  // const LightTooltip = useCallback(
  //   (props) => {
  //     console.log("c", props);
  //     return <Tooltip {...props} className={styleclasses.tooltipmemo} />;
  //   },
  //   [styleclasses.tooltipmemo]
  // );

  // const LightTooltip = React.memo(LightTooltipMemo);
  let onClickApproveInvoice = async () => {
    console.log("fieldArray on approve", fieldArray);
    setApproveLoading(true);
    setnextdata(true); // if (keyChangeFlag == true) {

    let filteredArray = fieldArray.filter((element) => element.fieldName != "");
    const keycongigure = {
      method: "POST",
      url: SAVE_TRAINING_DATASET_URL,
      headers: authHeader(),
      data: {
        supplierName: values.name,
        entityDataset: filteredArray,
        invoiceId: invoiceID,
      },
    };
    try {
      const response = await API(keycongigure);
    } catch (error) {
      console.log("error", error);
    } finally {
      setKeyFlag(false);
    }
    // }
    const config = {
      method: "PUT",
      url: UPDATE_INVOICE_STATUS,
      headers: authHeader(),
      data: {
        // userId: getUserId(),
        // invoiceId: invoiceID,
        // status: "Approved",
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
            invoiceId: invoiceID,
            status: "Approved",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        //  Swal.fire(getAlertToast("Success", "Approved Successfully"));
        CustomMessage(
          "Approved Successfully.. Loading Next Invoice",
          "success",
          enqueueSnackbar
        ); //call toster

        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, status: "Approved" },
        });

        setFormValues({ ...formValues, status: "Approved" });
        setTimeout(() => {
          // CustomMessage("Approved Successfully", "success", enqueueSnackbar);
          onClickNextInvoice("approve");
          // CustomMessage("Approved Successfully", "success", enqueueSnackbar);
        }, 5000);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setApproveLoading(false);
    }
  };

  const getRaisedUser = async () => {
    const configkp = {
      method: "GET",
      url: GET_INVOICE_EXCEPTION_LIST,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configkp);
      let l = deCryptFun(response.data);
      const { status, data, totalCount } = JSON.parse(l);
      console.log("JSONParsed", JSON.parse(l));
      if (status == "Success") {
        if (totalCount > 0) {
          console.log("testexcep");
          //  var dataArr = data[data.length - 1];
          setraisedTo(
            data.map((datalist, i) => {
              return datalist.userId.toString();
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getRaisedUser();
  }, []);
  console.log(raisedTo, "raisedto");
  useEffect(() => {
    return () => dispatch({ type: TURN_FLAG, payload: false });
  }, []);

  let FieldCheck = () => {
    const Field = requiredFieldList.filter(
      (data) =>
        data.isRequired === 1 &&
        data.isVisible === 1 &&
        data.moduleName === "Invoice"
    );

    const FieldforTax = requiredFieldList.filter(
      (data) =>
        data.isRequired === 1 &&
        data.isVisible === 1 &&
        data.moduleName === "Invoice" &&
        data.columnName === "taxTotal"
    );

    var found = Field.filter(function (element) {
      return values[element.columnName];
    });

    var foundCount = parseInt(found.length);

    if (FieldforTax.length > 0) {
      if (values["taxTotal"] === 0) {
        foundCount = foundCount + 1;
      }
    }

    if (foundCount == Field.length) {
      Swal.fire({
        title: "Do you want to Approve?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
      }).then((result) => {
        if (result.isConfirmed) {
          onClickApproveInvoice();
        }
      });
    } else {
      // Swal.fire(
      //   getAlert(
      //     "Alert",
      //     "Required fields Are not filled. You won't be able to Approve this!"
      //   )
      // );
      CustomMessage(
        "Required fields Are not filled. You won't be able to Approve this!",
        "warning",
        enqueueSnackbar
      ); //call toster
    }
  };

  var fileDownload = require("js-file-download");

  const handleChangeTab = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handleChangeData = useCallback(
    (event, newValue) => {
      if (isEdit || isEditLines) {
        CustomMessage("Update or Cancel Editing", "warning", enqueueSnackbar);
        return null;
      }
      setDataValue(newValue);
    },
    [isEdit, isEditLines]
  );

  let zoomIn = "";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    //setPageNumber(1);
  }

  const getPreSelectValuesNewSupplier = async (
    newInvoiceId,
    newSupplierKeyPair
  ) => {
    setDetectLoading(true);
    const configps = {
      method: "GET",
      url: GET_TRAINED_DATASET_URL,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: newInvoiceId ? newInvoiceId : invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configps);

      // const { status, data = "" } = response.data;
      let l = deCryptFun(response.data);

      const { status, data = "" } = JSON.parse(l);

      if (status == "Success") {
        setDetectedData(data);
        let detectedArray = Object.keys(data);
        // console.log("detected Array", detectedArray)
        let newobjectCreated = detectedArray.map((el) => {
          //let keyofobj = el

          let properties = { label: data[el].label, value: formValues[el] };
          return properties;
        });
        // console.log("newObject", newobjectCreated)
        if (newobjectCreated) {
          let destructuredkeypair = newSupplierKeyPair.map((el) => {
            let properties = {};
            let datamatch = newobjectCreated.find(
              (val) => val.label == el.label
            );
            // console.log("datamatch", datamatch)
            if (newobjectCreated.find((val) => el.label === val.label)) {
              properties = { label: el.label, value: datamatch.value };
            } else {
              properties = { label: el.label, value: el.value };
            }
            return properties;
          });
          setDynamicVal(destructuredkeypair);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDetectLoading(false);
    }
  };

  const newSupplierData = useCallback(async (nameinput) => {
    //  console.log("supplier name on new supplier functioncall", nameinput, parseInt(invoiceID),)
    const getconfig = {
      method: "GET",
      url: GET_KEYVALUES_BY_SUPPLIERNAME,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            supplierName: nameinput,
            invoiceId: parseInt(invoiceID),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfig);
      let l = deCryptFun(response.data);
      // console.log("json parsed",JSON.parse(l) )
      const { status, invoiceId, documentData } = JSON.parse(l);
      let newInvoiceId = invoiceId;
      if (status == "Success") {
        if (documentData != "N/A") {
          // console.log("new supplier on response", documentData)
          let newSupplierLabelArray = documentData.map(function (obj) {
            return obj.label;
          });
          let newSupplierKeyPair = newSupplierLabelArray.map((el) => {
            let labelUpperCase = el.toUpperCase();
            let camelCaseWord = el.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            );
            let properties = {};

            let dataMatch = currentInvoiceKeyPair.find(
              (supp) => supp.label == el
            );
            let dataMatch1 = currentInvoiceKeyPair.find(
              (supp) => supp.label == `${el}:`
            );
            if (dataMatch) {
              properties = {
                label: el,
                value: dataMatch.value,
              };
            } else if (dataMatch1) {
              properties = {
                label: el,
                value: dataMatch1.value,
              };
            } else {
              properties = {
                label: el,
                value: "",
              };
            }
            return properties;
          });

          setDynamicVal(newSupplierKeyPair);
          // Swal.fire(getAlertToast("Success", "Supplier Changed Successfully"));
          CustomMessage(
            "Supplier Changed Successfully",
            "success",
            enqueueSnackbar
          ); //call toster

          getPreSelectValuesNewSupplier(newInvoiceId, newSupplierKeyPair);
        } else {
          //  console.log("if not data")
          Swal.fire({
            title: "",
            text: "No Record Found",
            // icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setDynamicVal([]);
            }
          });
          //Swal.fire(getAlert("Info", "No Record Found"));
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Info", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        setDynamicVal([]);
      }
    }
  }, []);

  const handleTeamMemberChange = useCallback(
    (teamMemberId, e) => {
      // console.log("teamMemberId", e.target.value)
      // setTeamMember(e.target.value)
      // //assignTeamMember()
      e.preventDefault();
      // console.log('assignToAnotherTM', teamMemberId);
      // console.log("assignteamMemberList", teamMemberList)
      let filterteamMember = teamMemberList.filter((el) => {
        return el.userId == teamMemberId;
      });
      //console.log("teamMemberName", filterteamMember[0].userName)
      let teamMemberName = filterteamMember[0].userName;
      Swal.fire({
        title: "",
        text: `Are you sure you want to assign this invoice to "${teamMemberName}"`,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "yes",
      }).then((result) => {
        if (result.isConfirmed) {
          assignTeamMember(teamMemberId);
        }
      });
      //assignTeamMember(teamMemberId)
    },
    [teamMemberId]
  );

  const assignTeamMember = useCallback(async (teamId) => {
    // let newStructuredData = teamMemberList.map(el => {
    //   let properties = {}
    //   properties = {
    //     "assignSupplierId": 0,
    //     "teamUserId": el.teamUserId,
    //     "supplierName": suppName,
    //     "isDeleted": el.isDeleted
    //   }
    //   return properties;
    // })
    let newStructuredData = [
      {
        assignSupplierId: assignSupplierId,
        teamUserId: teamId,
        supplierName: name,
        isDeleted: 0,
      },
    ];

    const config = {
      method: "POST",
      url: TEAM_MEMBER_ASSIGN,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            teamList: newStructuredData,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { message, status } = JSON.parse(l);
      // console.log("JSON PARSED", JSON.parse(l))
      if (status == "Success") {
        navigate(INVOICE_ROUTE);
        // Swal.fire(getAlertToast("success", "Invoice assigned successfully"));
        CustomMessage(
          "Invoice assigned successfully",
          "success",
          enqueueSnackbar
        ); //call toster
      }
      // setSupplierList(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  }, []);

  const [inviteSuppliermodal, setInviteSuppliermodal] = React.useState(false);

  const successCall = (message) => {
    console.log("message", message);
    if (message != "cancel") {
      navigate(INVOICE_ROUTE);
    } else {
      setInviteSuppliermodal(false);
    }
  };

  const [list, setList] = useState();

  const getAudit = async (value) => {
    // setGetLoading(true);
    const getconfig = {
      method: "GET",
      url: GET_AUDIT_URL,
      headers: authHeader(),
      params: {
        //invoiceId: invoiceId,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfig);
      // const { status, message, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, data } = JSON.parse(l);
      setList(data);
      // console.log(response);
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire(getAlert("Error",  v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      // setGetLoading(false);
    }
  };

  useEffect(() => {
    getAudit();
    DownloadCSV();
  }, [invoiceID]);
  const handleRedirect = useCallback(
    () => setInviteSuppliermodal((v) => !v),
    []
  );

  const updateStatus = async () => {
    const config = {
      method: "PUT",
      url: UPDATE_INVOICE_STATUS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
            invoiceId: invoiceID,
            status: "Pending",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Exception has been resolved "));
        CustomMessage(
          "Exception has been resolved ",
          "success",
          enqueueSnackbar
        ); //call toster

        setMoveStatus(true);
        navigate(INVOICE_ROUTE);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const rejectInvoice = async (rejectComment) => {
    const statusBackrej = "reject";
    const config = {
      method: "PUT",
      url: UPDATE_INVOICE_STATUS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
            invoiceId: invoiceID,
            status: "Rejected",
            comments: rejectComment,
          })
        ),
        flutterString: "",
      },
    };
    setRejectLoading(true);
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Rejected Successfully"));
        CustomMessage("Rejected Successfully", "success", enqueueSnackbar); //call toster
        setFormValues({ ...formValues, status: "Rejected" });

        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, status: "Rejected" },
        });
        // onClickNextInvoice();
        navigate(INVOICE_ROUTE);
        // updateCompleteException(rejectComment,statusBackrej)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setRejectLoading(false);
    }
  };

  const updateCompleteException = async (comments, statusEx) => {
    const config = {
      method: "PUT",
      url: UPDATE_EXCEPTION_STATUS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            actionBy: getUserId(),
            invoiceId: invoiceID,
            exceptionStatus: "Completed",
            comments: comments,
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        if (statusEx == "pending") {
          updateStatus();
          // CustomMessage("succes", "success", enqueueSnackbar);
        } else {
          CustomMessage("Invoice Rejected", "success", enqueueSnackbar);
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const updateCompleteExceptionReject = async (comments) => {
    const config = {
      method: "PUT",
      url: UPDATE_EXCEPTION_STATUS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            actionBy: getUserId(),
            invoiceId: invoiceID,
            exceptionStatus: "Completed",
            comments: comments,
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        rejectInvoice(comments);
        // if (statusEx == "pending") {
        //   updateStatus()
        //   // CustomMessage("succes", "success", enqueueSnackbar);
        // } else {
        //   CustomMessage("Invoice Rejected", "success", enqueueSnackbar);
        // }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const onDocumentError = async (e) => {
    console.log("error", e);
  };

  const changePage = useCallback(
    (offset) =>
      setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    []
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);
  const nextPage = useCallback(() => changePage(1), [changePage]);

  //console.log(pageNumber);

  let ns;
  if (emailbody) {
    ns = emailbody.split(/\r?\n/);
  }

  const saveFormValues = async (values) => {
    console.log("kavi", values);
    // update
    // if (keyChangeFlag == true) {
    setUpdateKey(true);

    const keycongigure = {
      method: "POST",
      url: TRAINING_DATA_STORAGE_URL,
      headers: authHeader(),
      data: {
        invoiceId: parseInt(invoiceID),
        entityDatasetDetails: fieldArray,
      },
    };
    try {
      const response = await API(keycongigure);
    } catch (error) {
      console.log("error", error);
    } finally {
      setKeyFlag(false);
    }
    // }

    dispatch({ type: TURN_FLAG, payload: !isEdit });
    //console.log("values on update", "invAmt",values.invoiceAmount ?  values.invoiceAmount.toString() : "", "dueAmt",values.dueAmount ? values.dueAmount.toString() :"", "tax", values.taxTotal ? values.taxTotal.toString() : "")
    setLoading(true);
    const config = {
      method: "PUT",
      url: UPDATE_INVOICE,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            ...values,

            // taxTotal: values.taxTotal ? values.taxTotal.toString() : "",
            // invoiceAmount: values.invoiceAmount ? values.invoiceAmount.toString() : "",
            // dueAmount: values.dueAmount ? values.dueAmount.toString() : "",

            taxTotal: values.taxTotal
              ? parseFloat(values.taxTotal.replace(/\$|,/g, "")).toString()
              : "",
            invoiceAmount: values.invoiceAmount
              ? parseFloat(values.invoiceAmount.replace(/\$|,/g, "")).toString()
              : "",
            dueAmount: values.dueAmount
              ? parseFloat(values.dueAmount.replace(/\$|,/g, "")).toString()
              : "",

            invoiceDate: moment(values.invoiceDate).format("YYYY-MM-DD"),
            dueDateYYYMMDD: moment(values.dueDateYYYMMDD).format("YYYY-MM-DD"),
            dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
            updateBy: parseInt(getUserId()),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      // const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        CustomMessage("Updated Successfully", "success", enqueueSnackbar); //call toster
        dispatch({ type: TURN_FLAG, payload: !isEdit });
        // window.location.reload();
        GetDetails();
        getAudit();
        getPreSelectValues();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire(getAlert("Error", v.message));
        //console.log("v.message")
      }
    } finally {
      setButtonClicked("updateButton");
      //setTimerFlag(false);
      unlockInvoice();
      setUpdateKey(false);
      setLoading(false);
    }
  };

  let validationSchema = Yup.object({
    invoiceNumber: Yup.string()
      .test("invoiceNumberTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    documentType: Yup.string()
      .test("documentTypeTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "documentType" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    invoiceDate: Yup.string()
      .test("invoiceDateTest", "Field is required", function (value) {
        //console.log("value", value)
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceDate" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    dueDate: Yup.string()
      // if (dateFormat == "") {
      //   setDate("MM/dd/yyyy");
      // } else {
      //   setDate(dateFormat);
      // }
      // .matches(TIME_MMDDYYYY_REGEX, "Invalid date")
      // .matches(
      //   (values.dueDate == ""
      //     ? VALID_DATE_MMDDYYYY_REGEX
      //     : VALID_DATE_DDMMYYYY_REGEX,
      //   "Allow preferred date format")
      // )

      .test("test", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "dueDate" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });

        return !value && found.length == 1 ? false : true;
        // return false;
      })
      // .matches(NUMBERS_AND_SLASH_SYMBOLS_REGEX, 'Accept only preferred date format')
      .nullable(),

    invoiceCurrency: Yup.string()
      .test("invoiceCurrencyTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceCurrency" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    invoiceAmount: Yup.string()
      .test("invoiceAmountTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceAmount" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .matches(CURRENCY_REGEX, "Invalid currency")
      .nullable(),
    dueAmount: Yup.string()
      .test("dueAmountTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "dueAmount" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .matches(CURRENCY_REGEX, "Invalid currency")
      .nullable(),
    taxTotal: Yup.string()
      .test("taxTotalTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "taxTotal" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .matches(CURRENCY_REGEX, "Invalid currency")
      .matches(NUMBERS_ONLY_REGEX, "Invalid currency")
      .nullable(),
    // totalAmount: Yup.string().nullable(),
    source: Yup.string()
      .test("sourceTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "source" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),

    paidAmount: Yup.string()
      //("Must be number")
      .test("paidAmountTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "paidAmount" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .matches(CURRENCY_REGEX, "Invalid currency")

      .matches(NUMBERS_ONLY_REGEX, "Invalid currency")
      // .positive()
      .nullable(),
    invoiceDescription: Yup.string()
      .test("invoiceDescriptionTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "invoiceDescription" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    senderEmail: Yup.string()
      .test("senderEmailTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "supplierEmail" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    orderNumber: Yup.string()
      .test("orderNumberTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "orderNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1 &&
            type == "po"
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    name: Yup.string()
      .test("nameTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "name" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
    phoneNumber: Yup.string()
      .test("phoneNumberTest", "Field is required", function (value) {
        var found = requiredFieldList.filter(function (element) {
          return (
            element.columnName === "phoneNumber" &&
            element.isRequired === 1 &&
            element.isVisible === 1
          );
        });
        return !value && found.length === 1 ? false : true;
      })
      .nullable(),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    resetForm,
    touched,
    setTouched,
    setValues,
    setErrors,
    setFieldTouched,
  } = useFormik({
    initialValues: { ...formValues },
    enableReinitialize: true,
    validationSchema,
    onSubmit(values) {
      saveFormValues(values);
    },
  });

  const checkHandleChange = (e) => {
    console.log(e.target.name, e.target.value);
    // setValues((v) => ({ ...v, [e.target.name]: e.target.value }));
    handleChange(e);
  };
  const checkvalidateFields = (e) => {
    //console.log(e.target.name, e.target.value);
    // setValues((v) => ({ ...v, [e.target.name]: e.target.value }));
    handleChange(e);
  };

  // console.log(values, "values");
  const {
    orderNumber,
    status,
    filePath,
    invoiceStatus,
    supplierStatus,
    invoicePOStatus,
    invoiceNumber,
    invoiceAmount,
    dueAmount,
    senderEmail,
    extractEngineFailed,
    name,
    assignSupplierId,
    supplierName,
    invoiceCurrency,
  } = values;

  console.log(
    "Values:",
    values,
    "Order Number:",
    orderNumber,
    "Due date:",
    values.dueDate,
    new Date(values.dueDate)
  );

  const errorFun = async (message) => {
    Swal.fire({
      title: "",
      text: message,
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };

  let GetInitialValues = async () => {
    console.log("Calling GET Initial Values");
    //  console.log("supplier name before cancel", name);
    const config = {
      method: "GET",
      url: GET_INVOICE_DETAILS,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    // setLoading(true);
    try {
      window.setTimeout(
        () =>
          //  Swal.fire(getAlertToast("success", "Loading....")
          // )
          CustomMessage("Loading....", "success", enqueueSnackbar), //call toster

        1000
      );
      const response = await API(config);
      //const { status, data } = response.data;

      let l = deCryptFun(response.data);

      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        console.log("GET Initial Values:", data, data[0]);
        const initialData = data[0];
        const reformattedData = {
          ...initialData,

          dueDate: initialData.dueDate
            ? moment.parseZone(initialData.dueDate).format("MM/DD/YYYY")
            : "",
          invoiceDate: initialData.invoiceDate
            ? moment.parseZone(initialData.invoiceDate).format("MM/DD/YYYY")
            : "",
          dueDateYYYMMDD: initialData.dueDateYYYMMDD
            ? moment.parseZone(initialData.dueDateYYYMMDD).format("MM/DD/YYYY")
            : null,
        };
        dispatch({ type: UPDATE_FORMDATA, payload: { ...reformattedData } });
        setFormValues(reformattedData);
        console.log("ispo", initialData.isPO);
        //onCancelSupplierKeyPair(initialData.name)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        errorFun(v.message);
      }
    } finally {
      //  setLoading(false);
      setDateShow(!dateShow);
    }
  };

  const unlockInvoice = async () => {
    const config = {
      method: "DELETE",
      url: UNLOCK_INVOICE_URL,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        // lockedBy: getUserId(),
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            lockedBy: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        // console.error("success");
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //setLockFlag(false);
      localStorage.setItem("EDIT_IN_PROCESS", false);
    }
  };
  const onPoRadioChange = (e) => {
    setType("po");
  };

  const onNonpoRadioChange = (e) => {
    setType("nonpo");
  };

  const [count, setCount] = useState(0);
  const [commentCount, setcommentCount] = useState();
  const getComment = async (value) => {
    const getconfig = {
      method: "GET",
      url: COMMENT_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            postedBy: value ? value : "",
            invoiceId: invoiceID,
            count: commentCount,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfig);
      // const { status, message, data, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, data, count } = JSON.parse(l);
      setcommentCount(count);
      setCount(count);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };

  useEffect(() => {
    getComment();
  }, [commentCount]);

  const getAssignerList = async () => {
    //console.log("getAssignerList call");
    const getconfig = {
      method: "GET",
      url: GET_ASSIGNER_LIST,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            approvalAmountTo: invoiceAmount ? invoiceAmount : 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfig);
      // const { status, message, data, count } = response.data;
      let l = deCryptFun(response.data);

      const { status, message, data, count } = JSON.parse(l);
      //  console.log("jsonParsed assigner list",JSON.parse(l) )

      if (status === "Success") {
        setTeamMemberList(data);
      }
      setCount(count);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };

  let CancelEdit = useCallback(async () => {
    resetForm({});
    getPreSelectValues();
    getKeyPairValues();
    GetInitialValues();
    //setTimerFlag(false);
    dispatch({ type: TURN_FLAG, payload: false });
    unlockInvoice();
    setButtonClicked("cancelButton");
  }, []);

  const getAssignedGlcode = async (value, id) => {
    //console.log("assignedCall", value, id);

    const config = {
      method: "GET",
      url: GET_ASSIGNED_GLCODE_VALUE,
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            tagName: "glCode",
            tagedTo: name,
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        // console.log("supplierDataontagged", data);
        // setSupplierLists(data);
        //console.log("assignedData", data);
        setGlCode(data[0].glCode);
      }
    } catch (error) {
      //   let { data } = error.response;
      //console.log("error", error);
    }
  };

  const tabToggle = useCallback((tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }, []);

  const onClickEdit = async () => {
    setButtonClicked("editButton");

    tabToggle("1");
    //setTimerFlag(true);
    //setLockFlag(true);
    const config = {
      method: "POST",
      url: LOCK_INVOICE_URL,
      headers: authHeader(),
      data: {
        // invoiceId: invoiceID,
        // lockedBy: getUserId(),
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            lockedBy: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        if (
          parseInt(data.lockedUserId) > 0 &&
          parseInt(data.lockedUserId) !== parseInt(getUserId())
        ) {
          // Swal.fire(
          //   getAlertToast(
          //     "error",
          //     "This invoice is locked for editing as another user is currently editing invoice fields."
          //   )
          // );
          CustomMessage(
            "This invoice is locked for editing as another user is currently editing invoice fields.",
            "error",
            enqueueSnackbar
          ); //call toster
        } else {
          dispatch({ type: TURN_FLAG, payload: !isEdit });

          localStorage.setItem("EDIT_IN_PROCESS", true);
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const getPreSelectValues = async () => {
    setDetectLoading(true);
    const configps = {
      method: "GET",
      url: GET_TRAINED_DATASET_URL,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configps);

      // const { status, data = "" } = response.data;
      let l = deCryptFun(response.data);
      // console.log("detected parsed",JSON.parse(l))
      const { status, data = "" } = JSON.parse(l);

      if (status == "Success") {
        setDetectedData(data);
        setDetectedTableData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDetectLoading(false);
    }
  };

  const getKeyPairValues = async () => {
    const configkp = {
      method: "GET",
      url: EXTRACTION_FORM_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    try {
      //console.log("INSIDE TRY")
      const response = await API(configkp);
      // console.log("TRY response", response)
      let l = deCryptFun(response.data);
      const { status, documentData = "", detectedEntities } = JSON.parse(l);
      //  console.log("JSONParsed", JSON.parse(l))
      if (status == "Success") {
        if (documentData != "N/A") {
          console.log("documentData", documentData);
          setDynamicVal(documentData);
          setCurrentInvoiceKeyPair(documentData);
        } else {
          setDynamicVal([]);
          setCurrentInvoiceKeyPair([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPreSelectValues();
    getKeyPairValues();
  }, [invoiceID]);

  useEffect(() => {
    // console.log("approvalAmount", getApprovalAmount(), "invoiceAmount", invoiceAmount)
    // console.log(getRole());
    //console.log(invoiceAmount);
    // getAssignerList();
    if (getRole() === "Team Member" && invoiceAmount >= 0) {
      getAssignerList();
    }
  }, [invoiceAmount]);

  useEffect(() => {
    if (name == "" || name == null) {
      //console.log("nodata to generate");
    } else {
      //console.log("generate");
      getAssignedGlcode();
    }
  }, [name, invoiceAmount, invoiceCurrency, invoiceID]);

  let GetDetails = async () => {
    // console.log(invoiceID);
    const config = {
      method: "GET",
      url: GET_INVOICE_DETAILS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    try {
      window.setTimeout(
        () =>
          // Swal.fire(getAlertToast("success", "Loading...."))
          CustomMessage("Loading....", "success", enqueueSnackbar), //call toster

        1000
      );
      const response = await API(config);
      let l = deCryptFun(response.data);

      // console.log(l);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        console.log("GET DETAILS:", data, data[0]);
        const initialData = data[0];
        console.log("data", data[0]);
        const {
          teamId,
          emailContentFilePath,
          analysisJobId,
          filePath,
          validationResponse,
          invoiceNumber,
          status,
        } = initialData;

        setJobId(analysisJobId);
        setSubTeamId(teamId);
        setMessage(emailContentFilePath);
        localStorage.setItem("TEAM_SUB_ID", teamId);
        setInvoiceFilePath(filePath);
        setInvNum(invoiceNumber);
        setStatusOnLoad(status);

        setType(initialData.orderNumber ? "po" : "nonpo");

        const reformattedData = {
          ...initialData,
          dueDate: initialData.dueDate
            ? moment.parseZone(initialData.dueDate).format("MM/DD/YYYY")
            : "",
          invoiceDate: initialData.invoiceDate
            ? moment.parseZone(initialData.invoiceDate).format("MM/DD/YYYY")
            : "",
          dueDateYYYMMDD: initialData.dueDateYYYMMDD
            ? moment.parseZone(initialData.dueDateYYYMMDD).format("MM/DD/YYYY")
            : "",
        };
        setFormValues(reformattedData);

        dispatch({ type: UPDATE_FORMDATA, payload: { ...reformattedData } });

        if (data[0].filePath !== null) {
          const config = {
            method: "POST",
            url: ACCESS_S3_FILE,
            data: {
              filePath: data[0].filePath,
            },
            headers: authHeader(),
          };
          try {
            const response = await API(config);
            //let l = deCryptFun(response.data);
            const { status, url } = response.data;
            if (status === "Success") {
              setPreviewUrl(url);
            }
          } catch (error) {
            let errorObj = Object.assign({}, error);
            let { data } = errorObj.response;
            let { message } = data;
            // Swal.fire(getAlert("Error", message));
            CustomMessage(message, "error", enqueueSnackbar); //call toster
          }
        }
      }
    } catch (error) {
      let errorObj = Object.assign({}, error);
      let { data } = errorObj.response;
      let { message } = data;
      // Swal.fire(
      //   getAlert("Error", message ? message : "Invoice doesn’t exists")
      // );
      // CustomMessage(
      //   message ? message : "Invoice doesn’t exists",
      //   "error",
      //   enqueueSnackbar
      // ); //call toster
      if (message === undefined) {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "Invoice doesn’t exists",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate(INVOICE_ROUTE);
          }
        });
      } else {
        CustomMessage(message, "error", enqueueSnackbar);
      }
      // if (message === undefined) {

      // }
    } finally {
      //  setLoading(false);
    }
  };

  const GetRequiredValues = async () => {
    const config = {
      method: "GET",
      url: GET_INVOICE_FIELDS,
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() == "Supplier" ? subTeamId : getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        setRequiredFieldList(data);
        //console.log("fieldList", data)
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const [suppFlag, setSupFlag] = useState(false);
  const [approverFlag, setApproverFlag] = useState(false);
  const [suplierFlag, setSuplierFlag] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [supplierListnew, setSupplierListnew] = useState([]);

  const getEmailList = async () => {
    const config = {
      method: "GET",
      url: GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            supplierId: getEmail(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);
      // console.log("supplier List", data)
      setSupplierList(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        //Swal.fire(getAlert("Error", v.message));
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const getSupplierList = async () => {
    const config = {
      method: "GET",
      url: GET_SUPPLIER_LIST,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() != "Supplier" ? getTeamID() : 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);
      // console.log("supplier List", data)
      setSupplierListnew(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        //Swal.fire(getAlert("Error", v.message));
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  useEffect(() => {
    if (getRole() != "Supplier") {
      getEmailList();
    }
  }, []);

  useEffect(() => {
    getSupplierList();
  }, []);

  const notifySupplier = () => {
    setSupFlag(true);
  };

  const notifyApprover = () => {
    setApproverFlag(true);
  };

  const supplierListvisible = () => {
    setSuplierFlag(true);
  };

  let CloseToggle = () => {
    setSupFlag(false);
  };

  let CloseToggleApprover = () => {
    setApproverFlag(false);
  };
  let CloseToggleSupplier = () => {
    setSuplierFlag(false);
  };

  useEffect(() => {
    // console.log("test1");
    GetDetails();
    // GetRequiredValues();
  }, [invoiceID]);

  useEffect(() => {
    GetRequiredValues();
    // console.log("test2");
  }, [subTeamId, invoiceID]);

  let DetailsToggle = () => {
    //console.log("test12");
    setToggle(!Toggle);
  };

  const [screenDisable, setScreenDisable] = useState();

  let handlescreenDisable = (data) => {
    setScreenDisable(data);
  };

  const [page, setPage] = React.useState(1);
  const handleChangepage = useCallback((event, value) => {
    // console.log(value);
    setPage(value);
    setPageNumber(value);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // let statusBacktoClerk = () => {
  //   let statusBack = "pending";

  //   updateCompleteException(statusBack);
  // };

  const statusBacktoClerk = useCallback(() => {
    let statusBack = "pending";
    Swal.fire({
      input: "textarea",
      inputLabel: "Give Comments...",
      inputPlaceholder: "Give Comments to Exception invoice",
      inputAttributes: {
        "aria-label": "Comments",
      },
      showCancelButton: true,
      confirmButtonText: "ok",
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          // console.log("commented value", result.value)
          let exceptionComment = result.value;
          postExceptionComments(exceptionComment);
          updateCompleteException(exceptionComment, statusBack);
        } else {
          // Swal.fire(getAlertToast("Message", "Please give comments to reject"));
          CustomMessage("Please give comments ", "info", enqueueSnackbar); //call toster
        }
      }
    });
  }, []);

  const postExceptionComments = async (comments) => {
    const config = {
      method: "POST",
      url: CREATE_COMMENT_URL,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            postedBy: getUserId(),
            comment: comments,
            tagBy: "",
            invoiceId: invoiceID,
            exceptionComments: "@Invoice Exception Comments",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      console.log("commemt json", JSON.parse(l));
      if (status === "Success") {
        //Swal.fire(getAlertToast("Success", "Approved Successfully"));
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };
  const onClickRejectInvoice = useCallback(() => {
    Swal.fire({
      input: "textarea",
      inputLabel: "Give Comments...",
      inputPlaceholder: "Give Comments to reject invoice",
      inputAttributes: {
        "aria-label": "Comments",
      },
      showCancelButton: true,
      confirmButtonText: "Reject",
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          // console.log("commented value", result.value)
          let rejectComment = result.value;
          //  rejectInvoice(rejectComment)
          updateCompleteExceptionReject(rejectComment);
        } else {
          // Swal.fire(getAlertToast("Message", "Please give comments to reject"));
          CustomMessage(
            "Please give comments to reject",
            "info",
            enqueueSnackbar
          ); //call toster
        }
      }
    });
  }, []);

  const [downloadURL, setDownloadURL] = useState("");

  let DownloadAuditPDF = async (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Audit";

    const headers = [["NAME", "ACTIONS", "TIME"]];

    const data = list.map((elt) => [
      elt.actionName,
      elt.comment,
      moment.parseZone(elt.actionDate).format("hh:mm A"),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      invoiceNumber != "N/A"
        ? `invoice${invoiceNumber}_${invoiceID}.pdf`
        : `invoice_${invoiceID}.pdf`
    );
  };

  const [CSVData, setCSVData] = useState("");

  const DownloadCSV = async () => {
    const config = {
      method: "GET",
      url: GET_INVOICE_DETAILS,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
          })
        ),
        flutterString: "",
      },
    };
    //setLoading(true);
    try {
      window.setTimeout(
        () =>
          //  Swal.fire(getAlertToast("success", "Loading....")
          // )
          CustomMessage("Loading....", "success", enqueueSnackbar), //call toster

        1000
      );
      const response = await API(config);
      //const { status, data } = response.data;

      let l = deCryptFun(response.data);

      const { status, data } = JSON.parse(l);
      // console.log("data", data[0].textractJson);

      if (status === "Success") {
        const config = {
          method: "POST",
          url: ACCESS_S3_FILE,
          data: {
            filePath: data[0].textractJson,
          },
          headers: authHeader(),
        };
        const response = await API(config);
        const { status, url } = response.data;

        if (status === "Success") {
          setCSVData(url);
          Axios.get(url, {
            //responseType: "blob",
          }).then((res) => {
            // console.log(res.data);
          });
        }
      }
    } finally {
      //setLoading(false);
    }
  };

  let Download = async (e) => {
    const urlfromSelection = e.currentTarget.dataset.url;
    const flag = e.currentTarget.dataset.flag;
    if (urlfromSelection) {
      const config = {
        method: "POST",
        url: ACCESS_S3_FILE,
        data: {
          filePath: urlfromSelection,
        },
        headers: authHeader(),
      };
      try {
        const response = await API(config);
        const { status, url } = response.data;

        if (status === "Success") {
          setDownloadURL(url);
          if (flag === "pdf") {
            Axios.get(url, {
              responseType: "blob",
            }).then((res) => {
              fileDownload(
                res.data,
                invoiceNumber != "N/A"
                  ? `invoice${invoiceNumber}_${invoiceID}.pdf`
                  : `invoice_${invoiceID}.pdf`
              );
            });
          } else if (flag === "json") {
            Axios.get(url, {
              responseType: "blob",
            }).then((res) => {
              fileDownload(
                res.data,
                invoiceNumber != "N/A"
                  ? `invoice${invoiceNumber}_${invoiceID}.json`
                  : `invoice_${invoiceID}.json`
              );
            });
          } else if (flag === "csv") {
            Axios.get(url, {
              responseType: "blob",
            }).then((res) => {
              // console.log(res.data);
              fileDownload(
                res.data,
                invoiceNumber != "N/A"
                  ? `invoice${invoiceNumber}_${invoiceID}.csv`
                  : `invoice_${invoiceID}.csv`
              );
            });
          }
        }
      } catch (error) {
        let errorObj = Object.assign({}, error);
        let { data } = errorObj.response;
        let { message } = data;
        // Swal.fire(getAlertToast("Error", message));
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      }
    } else {
      // Swal.fire(getAlertToast("error", "No file is found!"));
      CustomMessage("No file is found!", "error", enqueueSnackbar); //call toster
    }
  };

  const [exceptionFlagModel, setExceptionFlagModel] = useState(false);

  const openExceptionHandler = () => {
    setExceptionFlagModel(true);
  };
  let closeExceptionHandler = () => {
    setExceptionFlagModel(false);
  };

  const Refresh = useCallback(() => {
    window.location.reload();
  }, []);

  let onClickNextInvoice = useCallback(
    async (param) => {
      console.log("buttonClicked", param);
      if (isEdit) {
        Swal.fire("Message", "Update or Cancel current invoice");
      } else {
        //Swal.fire(getAlertToast("success", "Loading...."));
        {
          CustomMessage(
            param == "approve" ? "Loading ...." : "Loading Next Invoice",
            "success",
            enqueueSnackbar
          );
        }
        setnextdata(false);

        // CustomMessage("Loading Next Invoice....", "success", enqueueSnackbar);
        let amount;
        const configuser = {
          method: "GET",
          url: GET_USER_BY_ID,
          headers: authHeader(),
          params: {
            // userId: getUserId(),
            webString: enCryptFun(
              JSON.stringify({
                userId: getUserId(),
              })
            ),
            flutterString: "",
          },
        };
        try {
          const response = await API(configuser);
          // const { status, data } = response.data;
          let l = deCryptFun(response.data);
          const { status, data } = JSON.parse(l);
          if (status === "Success") {
            // console.log("next invoice getuser", data[0])
            const { approvalAmountTo } = data[0];

            amount = approvalAmountTo;
            dispatch({ type: TURN_FLAG, payload: false });
            //console.log("amount", amount)
          }
        } catch (error) {
          //Swal.fire("Error", error);
          if (error.response) {
            let { data } = error.response;
            let p = deCryptFun(data);
            let v = JSON.parse(p);
            // console.log("error data new",  v.message)
            Swal.fire("Error", v.message);
          }
        }
        //  console.log("invoice id passing", invoiceID)
        const config = {
          method: "GET",
          url: NEXT_INVOICE_URL,
          headers: authHeader(),
          params: {
            webString: enCryptFun(
              JSON.stringify({
                teamId:
                  getRole() != "Supplier" || amount === -1 ? getTeamID() : 0,
                senderEmail: getRole() == "Supplier" ? getSenderEmail() : "",
                currentInvoiceId: invoiceID,
              })
            ),
            flutterString: "",
          },
        };
        try {
          const response = await API(config);
          // const { status, data, message } = response.data;
          let l = deCryptFun(response.data);
          const { status, data, message } = JSON.parse(l);
          if (status === "Success") {
            if (data.length === 0) {
              Swal.fire(
                getAlertToast("warning", "Next invoice is not available!")
              );
            } else {
              // console.log("next invoice url data", data)
              // console.log(
              //   `${INVOICEPREVIEW_ROUTE}/${data[0].invoiceId}`,
              //   invoiceID
              // );
              // setLocationChange((val) => {
              //   return val + 1;
              // });
              navigate(`${INVOICEPREVIEW_ROUTE}/${data[0].invoiceId}`, {
                state: { locationChange: false },
              });
              const initialData = data[0];
              const reformattedData = {
                ...initialData,
                // invoiceDate: initialData.invoiceDate ? moment(initialData.invoiceDate).format("MM/DD/YYYY") : "",
                dueDate: initialData.dueDate
                  ? moment(initialData.dueDate).format("MM/DD/YYYY")
                  : "",
                invoiceDate: initialData.invoiceDate
                  ? moment
                      .parseZone(initialData.invoiceDate)
                      .format("MM/DD/YYYY")
                  : "",
                dueDateYYYMMDD: initialData.dueDateYYYMMDD
                  ? moment
                      .parseZone(initialData.dueDateYYYMMDD)
                      .format("MM/DD/YYYY")
                  : "",
              };
              dispatch({
                type: UPDATE_FORMDATA,
                payload: { ...reformattedData },
              });
              setFormValues(reformattedData);

              if (data[0].filePath !== null) {
                const config = {
                  method: "POST",
                  url: ACCESS_S3_FILE,
                  data: {
                    filePath: data[0].filePath,
                  },
                  headers: authHeader(),
                };
                try {
                  const response = await API(config);
                  const { status, url } = response.data;
                  if (status === "Success") {
                    setPreviewUrl(url);
                  }
                } catch (error) {
                  let errorObj = Object.assign({}, error);
                  let { data } = errorObj.response;
                  let { message } = data;
                  Swal.fire(getAlertToast("Error", message));
                }
              }
            }
          } else {
            Swal.fire(getAlertToast("warning", message));
          }
        } catch (error) {
          let errorObj = Object.assign({}, error);
          let { data } = errorObj.response;
          let { message } = data;
          Swal.fire(getAlertToast("Error", message));
        }
      }
    },
    [invoiceID, nextData, isEdit]
  );

  const backClick = async () => {
    console.log("Working", locationChange);
    // setLocationChange((val) => {
    //   return val - 1;
    // });
    //.log("back");

    //history.push(INVOICE_ROUTE);

    if (!locationChange) {
      if (isEdit) {
        Swal.fire("Message", "Update or Cancel current invoice");
      } else {
        Swal.fire(getAlertToast("success", "Loading...."));
        unlockInvoice();
        navigate(-1);
      }
    }
  };

  const TitleneValue = React.useMemo(() => {
    //console.log("he", invoiceStatus);
    const titlle = {
      Valid: { className: styleclasses.correctbutton, title: "Invoice Valid" },
      "Doesn't exist in ERP": {
        className: styleclasses.cancelbutton,
        title: "Invoice (Doesn't exist in ERP)",
      },
      "": { className: styleclasses.infobutton, title: "Invoice Failed" },
      "Not Verified": {
        className: styleclasses.infobutton,
        title: "Invoice Failed",
      },
      Duplicate: {
        className: styleclasses.duplicatebutton,
        title: "Duplicate invoice",
      },
    };

    return titlle[invoiceStatus];
  }, [invoiceStatus]);

  const TitleneValue2 = React.useMemo(() => {
    //console.log("he", invoiceStatus);
    const titlle = {
      Valid: { className: styleclasses.correctbutton, title: "PO Valid" },
      "Doesn't exist in ERP": {
        className: styleclasses.cancelbutton,
        title: "PO (Doesn't exist in ERP)",
      },
      "": { className: styleclasses.infobutton, title: "PO Failed" },
      "Not Verified": {
        className: styleclasses.infobutton,
        title: "PO Failed",
      },
    };

    return titlle[invoicePOStatus];
  }, [invoicePOStatus]);

  const TitleneValue3 = React.useMemo(() => {
    const titlle = {
      Valid: { className: styleclasses.correctbutton, title: "Supplier Valid" },
      "Doesn't exist in ERP": {
        className: styleclasses.cancelbutton,
        title: "Supplier (Doesn't exist in ERP)",
      },
      "": { className: styleclasses.infobutton, title: "Supplier Failed" },
      "Not Verified": {
        className: styleclasses.infobutton,
        title: "Supplier Failed",
      },
    };

    return titlle[supplierStatus];
  }, [supplierStatus]);

  const [chatFlag, setChatFlag] = useState(false);
  const [Toggle1, setToggle1] = useState(false);
  const chatFun = () => {
    setChatFlag(true);
    setToggle1(!Toggle1);
  };
  const [checkValue, setcheckValue] = useState("");
  const tooltipStyle = useMemo(() => {
    return {
      marginLeft: "20px",
      position: "relative",
      bottom: "5px",
    };
  }, []);
  const stateIcon = useMemo(() => {
    const newValue = {
      Valid: <CheckRoundedIcon />,
      "Doesn't exist in ERP": <ClearSharpIcon />,
      Faild: <InfoOutlinedIcon />,
      "Not Verified": <InfoOutlinedIcon />,
      Duplicate: <ContentCopyIcon />,
    };

    return newValue[invoiceStatus] || <></>;
  }, [invoiceStatus]);

  const poStarticon = useMemo(() => {
    const newValue = {
      Valid: <CheckRoundedIcon />,
      "Doesn't exist in ERP": <ClearSharpIcon />,
      Faild: <InfoOutlinedIcon />,
      "Not Verified": <InfoOutlinedIcon />,
    };
    return newValue[invoicePOStatus] || <></>;
  }, [invoicePOStatus]);

  const supplierStatusIcon = useMemo(() => {
    const newValue = {
      Valid: <CheckRoundedIcon />,
      "Doesn't exist in ERP": <ClearSharpIcon />,
      Faild: <InfoOutlinedIcon />,
      "Not Verified": <InfoOutlinedIcon />,
    };
    return newValue[supplierStatus] || <></>;
  }, [supplierStatus]);

  //console.log(datavalue, value);

  const ApproveCheck = ({ disabled }) => {
    return (
      <Button
        className="resApproveBtn"
        color="primary"
        style={{
          width: "135px",
          color: "#fff",
          textTransform: "capitalize",
          background: disabled
            ? "#f0f0f0"
            : "#2B88F0 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          opacity: "1",
        }}
        onClick={FieldCheck}
        disabled={disabled}
      >
        {approveLoading && ""}
        Approve
      </Button>
    );
  };

  const ApproveButton = () => {
    if (!["Supplier", "Team Member"].includes(getRole())) {
      if (
        approveLoading ||
        supplierStatus !== "Valid" ||
        invoiceStatus !== "Valid" ||
        (type == "po" && invoicePOStatus !== "Valid") ||
        // (orderNumber && invoicePOStatus !== "Valid") ||
        // invoicePOStatus !== "Valid" ||
        [
          "Reject Invoice",
          "Supplier Maintenance",
          "Specialist Exception",
          "Request Information",
        ].includes(status)
      ) {
        return <ApproveCheck disabled={true} />;
      } else {
        return <ApproveCheck disabled={false} />;
      }
    } else if (
      // invoiceAmount > getApprovalAmount() &&
      getRole() == "Team Member"
    ) {
      // return <ApproveCheck disabled={true} />;
      if (dueAmount === "" || dueAmount > getApprovalAmount()) {
        return <ApproveCheck disabled={true} />;
      } else {
        return <ApproveCheck disabled={false} />;
      }
    } else {
      return <></>;
    }
  };

  const EditChangeLines = (flag) => {
    setIsEditLines(flag);
  };
  const checkInitalize = () => {
    // const invoiceID = invoiceID;
    console.log("from init", statusOnLoad);
    if (statusOnLoad === "Initializing") {
      console.log("from ini status");
      Swal.fire({
        text: "Invoice is being processed, Do you want to download the file?",
        icon: "Info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          downloadPdf(invoiceFilePath, InvNum, invoiceID);
        } else {
          navigate(INVOICE_ROUTE);
        }
      });
    }
    // if (status === "Reprocessing") {
    //   Swal.fire(getAlert("error", "Invoice is being processed, please wait."));
    // }

    // window.location.href(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`);
  };
  const downloadPdf = async (file, invoiceNum, invoiceID) => {
    console.log("fileName", file);
    console.log(file, invoiceNum, invoiceID);
    if (file) {
      console.log(file, invoiceNum, invoiceID);
      const config = {
        method: "POST",
        url: ACCESS_S3_FILE,
        data: { filePath: file },
        headers: authHeader(),
      };
      try {
        const response = await API(config);
        const { status, url } = response.data;

        if (status === "Success") {
          // setDownloadURL(url);

          Axios.get(url, {
            responseType: "blob",
          }).then((res) => {
            fileDownload(
              res.data,
              invoiceNum != ""
                ? `invoice${invoiceNum}.pdf`
                : `invoice_${invoiceID}.pdf`
            );
          });
          navigate(INVOICE_ROUTE);
        }
      } catch (error) {
        let errorObj = Object.assign({}, error);
        let { data } = errorObj.response;
        let { message } = data;
        Swal.fire(getAlertToast("Error", message));
      }
    } else {
      Swal.fire(getAlertToast("error", "No file is found!"));
    }
  };
  useEffect(() => {
    checkInitalize();
  }, [statusOnLoad, invoiceFilePath]);

  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid item xs={6} md={6} className={styleclasses.headerleft}>
          <Typography
            align="left"
            style={{ fontSize: "28px", fontWeight: 500 }}
          >
            Invoice : {formValues.invoiceNumber}
            {getRole() == "Supplier" ? (
              <LightTooltip title="Refresh" placement="top" arrow>
                <Button
                  style={tooltipStyle}
                  className={styleclasses.refreshbtn}
                  onClick={Refresh}
                >
                  <RefreshRoundedIcon />{" "}
                </Button>
              </LightTooltip>
            ) : (
              ""
            )}
          </Typography>
        </Grid>
        <Grid item sx={{ paddingTop: "0px !important" }} xs={6} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            {getRole() != "Supplier" ? (
              <LightTooltip title="Refresh" placement="top" arrow>
                <Button className={styleclasses.refreshbtn} onClick={Refresh}>
                  <RefreshRoundedIcon />{" "}
                </Button>
              </LightTooltip>
            ) : (
              ""
            )}
            <img src={TeamReducer.companyLogo} className="companyLogo"></img>
          </Stack>
        </Grid>
      </Grid>

      {/* {[
        "Invoice Rejection",
        "Supplier Maintenance",
        "Specialist Exception",
        "Request Information",
      ].includes(status) ? (
        <Grid xs={12} md={12}>
          <Button className={styleclasses.pendingbutton}>
            <Typography className={styleclasses.soucebutton}>
              An exception has been raised on this invoice, and this invoice
              will be unable to be approved until the exception is resolved by
              an exception handler.
            </Typography>
          </Button>
        </Grid>
      ) : (
        ""
      )} */}

      <Grid sx={{ pt: 1 }} container>
        <Box style={{ marginTop: "30px" }} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={12}>
            <Item sx={{ borderRadius: "16px" }}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", position: "relative" }}
              >
                <Grid item xs={12} md={12} sx={{ padding: "10px" }}>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ bgcolor: "#fff" }}>
                      <AntTabs
                        className={styleclasses.tabsmemo}
                        sx={{ borderBottom: "none" }}
                        value={value}
                        onChange={handleChangeTab}
                        aria-label="ant example"
                      >
                        <AntTabMemo
                          className={styleclasses.tamemo}
                          sx={{ fontSize: "16px !important" }}
                          label="Invoice Preview"
                        />
                        <AntTabMemo
                          className={styleclasses.tamemo}
                          sx={{ fontSize: "16px !important" }}
                          label="Email Message"
                        />
                      </AntTabs>
                      <Box sx={{ p: 3 }} />
                      {value == 0 && (
                        <div>
                          <TransformWrapper
                            defaultScale={1}
                            defaultPositionX={200}
                            defaultPositionY={100}
                          >
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                              <Fragment>
                                <Box className={styleclasses.tools}>
                                  <IconButton
                                    aria-label="add"
                                    className={styleclasses.previewbtn}
                                  >
                                    <AddCircleOutlineRoundedIcon
                                      onClick={zoomIn}
                                    />
                                  </IconButton>
                                  <IconButton
                                    aria-label="out"
                                    className={styleclasses.previewbtn}
                                  >
                                    <RemoveCircleOutlineRoundedIcon
                                      onClick={zoomOut}
                                    />
                                  </IconButton>
                                  <LightTooltip
                                    title="Preview"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      className={styleclasses.previewbtn}
                                    >
                                      <CropFreeIcon onClick={DetailsToggle} />
                                    </IconButton>
                                  </LightTooltip>
                                </Box>
                                <TransformComponent
                                  className="mainsviewpdf"
                                  style={{ margin: "auto !important" }}
                                >
                                  <Document
                                    className="sviewpdf"
                                    key={previewUrl}
                                    file={previewUrl}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={onDocumentError}
                                    noData="Loading PDF..."
                                  >
                                    <Page
                                      className="pagination-preview"
                                      pageNumber={pageNumber}
                                    />
                                  </Document>
                                  {/* )} */}
                                </TransformComponent>
                              </Fragment>
                            )}
                          </TransformWrapper>
                          {numPages ? (
                            <Stack spacing={2}>
                              <Pagination
                                count={numPages}
                                page={page}
                                onChange={handleChangepage}
                              />
                            </Stack>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {value == 1 && (
                        <div>
                          {" "}
                          {emailbody ? (
                            ns &&
                            ns.map((record, i) => {
                              return <p key={`mail_body_${i}`}>{record}</p>;
                            })
                          ) : (
                            <p>No Message</p>
                          )}
                        </div>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Item>
            <div className="chat-btn" onClick={chatFun}>
              {count > 0 && (
                <Badge
                  sx={{ left: "-7px", top: "-18px" }}
                  color="error"
                  badgeContent={count > 0 ? count : "0"}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                ></Badge>
              )}

              <img
                style={{ width: "32px", margin: "auto" }}
                src={chaticon}
              ></img>
            </div>
          </Grid>
          {Toggle && (
            <Preview
              filePath={formValues.filePath}
              fileContent={formValues.emailContentFilePath}
              Toggle={Toggle}
              DetailsToggle={DetailsToggle}
              renderMode="canvas"
            />
          )}

          {Toggle1 && (
            <ChatSection
              Toggle1={Toggle1}
              chatFun={chatFun}
              chatFlag={chatFlag}
              invoiceID={invoiceID}
            />
          )}
        </Box>
      </Grid>
    </>
  );
}
