import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  GET_ASSIGNED_GLCODE_VALUE,
  GET_ASSIGNED_TEAM_LIST,
  GET_SUPPLIER_LIST_URL,
  GET_TAG_VALUE,
  GET_USERS,
  IMPORT_GLCODE,
  SAVE_GLCODE_TAGVALUE,
} from "../comman/url";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getTeamID,
} from "../comman/function";
import API from "../constants/API";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import produce from "immer";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "./NoRecordFound";
import CSVReader from "react-csv-reader";
import { fontWeight } from "@mui/system";
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles((theme) => ({
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  notifydownloadbtn: {
    margin: "12px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#2B88F0 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#0663ca !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  updategrid: {
    top: "150px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "tagedTo",
    numeric: true,
    disablePadding: false,
    label: "Suppiler Name",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "GL Code",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className="custom-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Settingsglcode(props) {
  const styleclasses = useStyles();
  const [fileName, setfileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const newcsv = (fileData, name, originalFile) => {
    if (originalFile?.lastModified === lastModified) {
      CustomMessage("File is already uploaded", "error", enqueueSnackbar);
    }
    console.log("FileData:", fileData, name.name, originalFile?.lastModified);
    setfileName(name.name);
    setLastModified(originalFile?.lastModified);
    setModifyFile(modifyFile + 1);
    let glcode = fileData.toString();
    settagList(glcode);
  };

  const handleForce = (data, fileInfo, originalFile) =>
    newcsv(data, fileInfo, originalFile);
  const [supplierLists, setSupplierLists] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [tagList, settagList] = useState([]);
  const [tagValues, settagValues] = useState();
  let { supplierList } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [taggedData, setTaggedData] = useState([
    // {
    //   tagName: "suppliername",
    //   tagedTo: "",
    //   glCode: [],
    //   glCodeSelected: "",
    //   id: Date.now(),
    //   //dropDownValues: [supplierList],
    // },
  ]);
  const [dataChanged, setDataChanged] = useState(false);
  const [modifyFile, setModifyFile] = useState(0);
  const [lastModified, setLastModified] = useState("");

  const [suppName, setSuppName] = useState("");

  const [dense, setDense] = useState(false);
  const errorfun = (a, e) => {
    setfileName("");
    CustomMessage(
      "Incorrect File Format. Only CSV file is supported",
      "error",
      enqueueSnackbar
    );
  };
  const inputChnage = (e) => {
    console.log(e);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // let { supplierList } = props;
  // console.log("supplierprops", supplierList);

  const papaparseOptions = {
    // header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    // transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  // const newcsv = (fileData) => {
  //   if (fileData.length > 0) {
  //     let findKeys = fileData[0];
  //     //  console.log("findKeys", fileData);
  //     let keyColumn = Object.keys(findKeys);
  //     let dataList = keyColumn.map((columnNam, i) => {
  //       let valueColumn = fileData.map((el, i) => {
  //         return el[columnNam];
  //       });
  //       let properties = {
  //         ["tagName"]: columnNam,
  //         ["glCode"]: valueColumn.toString(),
  //       };
  //       return properties;
  //     });
  //     console.log(dataList);
  //     settagList(dataList);
  //   }
  // };

  const importglcode = async () => {
    console.log(tagList);
    const config = {
      method: "POST",
      url: IMPORT_GLCODE,
      data: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            glCode: tagList,
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Imported successfully"));
        CustomMessage("Imported successfully", "success", enqueueSnackbar); //call toster
        getTagValue();
        // settagList([]);
        // setfileName("");
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  // Avoid a layout jump when reaching the last page with empty rows.

  //Api Function
  useEffect(() => {
    if (
      (tagValues && tagValues.length === 0) ||
      (supplierList && supplierList.length === 0) 
    ) {
      //  console.log("No tagvalues");
    } else {
      console.log(
        "tagValues useEffect",
        tagValues,
        supplierList,
        supplierLists
      );
      let redesignedMap = supplierList.map((el, i) => {
        let filterGlCodeSelected = supplierLists.filter((supp) => {
          return supp.tagName == "Glcode" && supp.tagedTo == el.label;
        });

        let selected =
          filterGlCodeSelected && filterGlCodeSelected.length > 0
            ? filterGlCodeSelected[0].glCode
            : "";

        let properties = {
          tagName: "Glcode",
          tagedTo: el.label,
          glCode: tagValues,
          glCodeSelected: selected,
          id: Date.now() + i * 2,
        };
        return properties;
      });
      console.log("redesignedArray", supplierList, tagValues, supplierLists);
      setTaggedData(redesignedMap);
    }
  }, [supplierList, tagValues]);

  const handletagChange = (e, node) => {
    setDataChanged(true);
    //console.log("e.target", e.target);
    const { id, value, name } = node.props;
    // console.log(node);

    const values = produce(taggedData, (draft) => {
      const index = draft.findIndex((todo) => todo.id == id);
      if (index !== -1) draft[index][name] = value;
    });
    // console.log("handleChange", values);
    setTaggedData(values);
  };
  const getAssignedGlcode = async (value, id) => {
    //console.log("assignedCall", value, id);

    const config = {
      method: "GET",
      url: GET_ASSIGNED_GLCODE_VALUE,
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            tagName: "suppliername",
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
       console.log("supplierDataontagged", data);
        if(data.length){
         setSupplierLists(data);
        }else{
          setSupplierLists([])
        }
       
      }
    } catch (error) {
      //   let { data } = error.response;
      console.log("error", error);
    }
  };
  const getTagValue = async () => {
    const config = {
      method: "GET",
      url: GET_TAG_VALUE,
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        // console.log("tagList", data);
        // let tagNameArray = data.map(function (obj) {
        //   if (obj.tagName == "suppliername") {
        //     //  console.log(obj.glCode)
        //     return obj.glCode;
        //   }
        // });
        // let tagSplit = JSON.parse(tagNameArray[0] || "[]");
        // let newTagArray = tagSplit.map((el) => {
        //   return el.glCode;
        // });
        let newTagArray = data.glCode.map((el) => {
          return el.code;
        });
        // console.log("tagNameArray", JSON.parse(tagNameArray[0] || '[]'));
        // setTagNameList(tagNameArray);
        // console.log("newTagArray", newTagArray);
        settagValues(newTagArray);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const updateTags = async () => {
    console.log("updateCalled");
    let savedData = taggedData.map((el) => {
      let properties = {
        tagName: el.tagName,
        tagedTo: el.tagedTo,
        glCode: el.glCodeSelected,
      };
      return properties;
    });
    // console.log("savedData", savedData);
    const config = {
      method: "POST",
      url: SAVE_GLCODE_TAGVALUE,
      data: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            tagList: savedData,
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Saved successfully"));
        CustomMessage("Saved successfully", "success", enqueueSnackbar); //call toster
        setDataChanged(false);
        setUpdateFlag(!updateFlag);
      }
    } catch (error) {
      console.log("inside catch", error);
    }
  };
  useEffect(() => {
    getAssignedGlcode();
    getTagValue();
  }, [updateFlag]);

  useEffect(() => {
    if (tagList && tagList.length > 0) {
      importglcode();
    }
  }, [tagList]);
  return (
    <Grid item xs={12} md={12} sx={{ zIndex: "0" }}>
      <Grid
        xs={12}
        md={12}
        // className={styleclasses.updategrid}
        style={{
          display: "flex",

          textAlign: "initial",
        }}
      >
        <Box>
          <label htmlFor="contained-button-file">
            <Input
              // accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              style={{ background: "blue" }}
            />
            {/* <Button variant="contained" component="span"> */}
            <Box key={modifyFile || ""}>
              {" "}
              <CSVReader
                style={{ color: "transparent" }}
                title=""
                inputId="react-csv-reader-input"
                accept=".csv,text/csv"
                strict={true}
                cssClass="contained-button-file"
                label="Select file to import"
                onFileLoaded={(data, fileInfo, originalFile) =>
                  handleForce(data, fileInfo, originalFile)
                }
                parserOptions={papaparseOptions}
                onError={errorfun}
                onChange={inputChnage}
              />
            </Box>
            {/* Upload
          </Button> */}
          </label>
        </Box>
        <Box style={{ padding: "12px", fontWeight: "500" }}>{fileName}</Box>
      </Grid>
      <Grid xs={12} md={12} className={styleclasses.updategrid}>
        <Box>
          <Button
            className={styleclasses.notifydownloadbtn}
            variant="contained"
            onClick={updateTags}
            disabled={dataChanged == false}
          >
            Update
          </Button>
        </Box>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, mt: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {taggedData.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {stableSort(taggedData, getComparator(order, orderBy))
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((datalist, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const {
                          tagName,
                          tagedTo,
                          glCode = [],
                          glCodeSelected,
                          id,
                        } = datalist;
                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, name)}
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            // selected={isItemSelected}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align="center"
                              style={{ fontWeight: 450, fontSize: 14 }}
                            >
                              {tagedTo}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontWeight: 450, fontSize: 14 }}
                            >
                              {" "}
                              <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                  id={id}
                                  onChange={handletagChange}
                                  value={glCodeSelected}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem
                                    value={""}
                                    id={id}
                                    name="glCodeSelected"
                                  >
                                    Select
                                  </MenuItem>
                                  {glCode.map((valueData, i) => {
                                    return (
                                      <MenuItem
                                        id={id}
                                        name="glCodeSelected"
                                        value={valueData}
                                      >
                                        {valueData}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </>
                )}
                {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Grid>
  );
}
