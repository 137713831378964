import React from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  DASHBOARD_ROUTE,
  INVOICEPREVIEW_ROUTE,
  INVOICE_PREVIEW_ROUTE,
  INVOICE_ROUTE,
  METRICSINVOICE_ROUTE,
  MYTEAM_ROUTE,
  SETTINGS_ROUTE,
  SIDEBAR_ROUTE,
  SIGNIN_ROUTE,
  FORGOTPASSWORD_ROUTE,
  AUTHENTIFICATION_ROUTE,
  SUPPLIERMANAGEMENT_ROUTE,
  PROFILE_ROUTE,
  SIGNUP_ROUTE,
  MYCLIENTS_ROUTE,
  ADD_SUPPLIER,
  NOTIFICATION_ROUTE,
} from "../constants/RoutePaths";

import Dashboard from "./Dashboard";
import Invoice from "./Invoice";
import InvoicePreview from "./InvoicePreview";
import InvoiceSinglePreview from "./InvoiceSinglePreview";
import MyTeam from "./MyTeam";
import SupplierManagement from "./SupplierManagement";
import Settings from "./Settings";
import MetricsInvoice from "./MetricsInvoice";
import Profile from "./Profile";
import MyClients from "./MyClients";
import { PrivateRoute } from "../comman/mainfunction";
import NotFound from "./NotFound";
import Authentification from "./Authentification";
import { SnackbarProvider } from "notistack";
import { Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddSupplier from "./AddSupplier";
import NotificationList from "./Notification/NotificationList";
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
export default function Layouts() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar />

        <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 2 }}>
          <Routes>
            <Route
              path={DASHBOARD_ROUTE}
              element={
                <SnackbarProvider
                  ref={notistackRef}
                  action={(key) => (
                    <Button onClick={onClickDismiss(key)}>
                      <CloseIcon style={{ color: "#fff" }} />
                    </Button>
                  )}
                  maxSnack={2}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Dashboard />
                  {/* <CheckText /> */}
                </SnackbarProvider>
              }
            ></Route>
            <Route
              path={NOTIFICATION_ROUTE}
              element={<NotificationList />}
            ></Route>
            <Route path={INVOICE_ROUTE} element={<Invoice />}></Route>
            <Route
              path={SUPPLIERMANAGEMENT_ROUTE}
              element={<SupplierManagement />}
            ></Route>
            <Route path={MYCLIENTS_ROUTE} element={<MyClients />}></Route>
            <Route path={ADD_SUPPLIER} element={<AddSupplier />}></Route>
            <Route path={SETTINGS_ROUTE} element={<Settings />}></Route>
            <Route
              path={METRICSINVOICE_ROUTE}
              element={<MetricsInvoice />}
            ></Route>
            <Route
              path={`${INVOICEPREVIEW_ROUTE}/:${"invoiceID"}`}
              element={<InvoicePreview />}
            ></Route>
            <Route
              path={`${INVOICE_PREVIEW_ROUTE}/:${"invoiceID"}`}
              element={<InvoiceSinglePreview />}
            ></Route>
            <Route
              path={"" + INVOICEPREVIEW_ROUTE + "/:invoiceID"}
              element={<InvoicePreview />}
            ></Route>
            <Route path={PROFILE_ROUTE} element={<Profile />}></Route>
            <Route path={MYTEAM_ROUTE} element={<MyTeam />}></Route>
            {/* <Route
              path={AUTHENTIFICATION_ROUTE}
              element={<Authentification />}
            ></Route> */}
            <Route
              path={"*"}
              element={<Navigate to={"pagenotfound"} />}
            ></Route>
          </Routes>
        </Box>
      </Box>
    </>
  );
}
